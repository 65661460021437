import React from 'react';
import './../App.css';
import { Input, Button } from 'antd';
import {SERVER_URL} from '../constant';
import DateAddress from '../components/dateAddress';
import Logo from '../components/logo';
class EmailVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      seconds: 60,
      emailValid: true,
      id: ''
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({email: this.props.location.state.e})
      this.setState({id: this.props.location.state.id})
    }
    this.startTimer()
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  handleChange(field, value) {
    this.setState({[field]: value})
  }

  emailSignup() {
    const emailValid = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); //
    if (emailValid) {
      const that = this
      const body = {
        email: this.state.email,
        platform: 'Web',
        id: this.state.id
      }
      fetch(`${SERVER_URL}/signup`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then(response => response.json())
        .then((json) => {
          that.setState({seconds: 60}, () => {
            that.timer = 0
            that.startTimer()
          })
        })
        .catch(function(error) {
          console.log(error)
        });
    } else {
      this.setState({emailValid: false})
    }
  }

  countDown() {
    let seconds = this.state.seconds - 1;
    this.setState({seconds: seconds});

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
    }
  }

  render() {
    return (
      <div className="App">
        <header className="App-header backgroundLightGrey">
          <div className="card-header">
            {/* <img className="logoImage" src="https://mtexpo.com.my/wp-content/uploads/2020/02/LOGO-2020-trans.png" alt="MTE logo" /> */}
            <Logo/>
            <DateAddress/>
          </div>
          <span className="title welcomeMessage">For manual registration, ﬁrst we need to verify your email address. Please check your email address for registration email. Your email will contain the link to continue with your registration.</span>
          <div className="border">
            <span className="smallTitle">Step 1 步骤1:</span>
            <span className="smallSubtitleWithoutMarginBottom">We have sent a veriﬁcation email to:</span>
            <span className="smallSubtitle">我们已经发送了一封验证电子邮件至:</span>
            <Input
              className="emailInputClass"
              placeholder="Email 邮件"
              value={this.state.email}
              onChange={(event) => this.handleChange('email', event.target.value)}
            />
            {this.state.emailValid === false && <span className="title red">Email not valid!</span>}
            <span className="smallSubtitleWithoutMarginBottom">Didn’t receive the email? Please wait for 1 minute before retrying.</span>
            <span className="smallSubtitle">若您没有收到此邮件，请稍等一分钟后再重发。</span>
            <Button onClick={() => this.emailSignup()} className="nextButton" disabled={this.state.seconds !== 0}>
              Resend Email ({this.state.seconds}s)
            </Button>
            <hr/>
            <span className="smallTitle">Step 2 步骤2:</span>
            <span className="smallSubtitleWithoutMarginBottom"><span className="red bold">Check your email </span>and click on the link to verify your account.</span>
            <span className="smallSubtitle"><span className="red bold">查看你的邮件</span>然后点击链接以验证您的帐户。</span>
            {/* <img className="screenshotImage" src={require('../image/emailVerificationScreenShot.png')} alt="Email ScreenShot" /> */}
            <img className="screenshotImage" src={require('../image/emailRegistration.png')} alt="Email ScreenShot" />
            <hr/>
            <span className="smallTitle">Step 3 步骤3:</span>
            <span className="smallSubtitleWithoutMarginBottom">Continue with registration to receive your QR Code.</span>
            <span className="smallSubtitle">继续注册以接收您的条形码。</span>
            <img className="screenshotImage" src={require('../image/registerScreenshot.png')} alt="Register ScreenShot" />
            <br/>
          </div>
          <br/>
          <br/>
        </header>
      </div>
    );
  }
}

export default EmailVerification;
