import React from 'react';

function DateAddress(props) {
  return (
    <div>
      {/* <span className="title">8 - 10 NOVEMBER 2023</span> */}
      <span className="title">9 - 12 October 2024</span>
      <span className="title">MITEC, Kuala Lumpur Malaysia</span>
    </div>
  );
}

export default DateAddress;