import React from 'react';
import './../App.css';
import { Input, Button, notification } from 'antd';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import {SERVER_URL} from '../constant'
import queryString from 'query-string';
import DateAddress from '../components/dateAddress';
import Logo from '../components/logo';
class EmailNotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      id: null
    };
  }

  handleChange(field, value) {
    this.setState({[field]: value})
  }

  emailSignup() {
    const emailValid = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (emailValid) {
      const that = this
      const body = {
        email: this.state.email,
        platform: 'Web',
        id: this.state.id
      }
      fetch(`${SERVER_URL}/signup`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then(response => response.json())
        .then((json) => {
          const profile = { e: this.state.email }
          if (json.body.m === 'ene') {
            that.props.history.push('/emailverification', profile)
          } else if (json.body.m === 'ee' && json.body.s === 'c') {
            that.props.history.push('/emaillogin', profile)
          } else if (json.body.m === 'ee' && json.body.s === 'p') {
            that.props.history.push('/emailverification', profile)
          } else {
            notification.open({
              message: 'Error happened',
              description: 'Please consult staff for help',
              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
          }
        })
        .catch(function(error) {
          console.log(error)
        });
    } else {
      this.setState({emailValid: false})
    }
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search)
    if (query.id) {
      this.setState({id: query.id})
    }
    if (this.props.location.state) {
      this.setState({email: this.props.location.state.e})
    }
  }

  responseFacebook(response) {
    const that = this
    const body = {
      platform: 'facebook',
      platformID: response.id,
      id: this.state.id,
      profile: {
        name: response.name,
        email: response.email,
      },
    }
    fetch(`${SERVER_URL}/login`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then((json) => {
        const profile = { i: json.body.i, platform: 'Facebook', id: this.state.id }
        if (json.body.m === 'pne') {
          that.props.history.push('/register', profile)
        } else if (json.body.m === 'pe' && json.body.s === 'c') {
          that.props.history.push('/barcode', profile)
        } else if (json.body.m === 'pe' && json.body.s === 'p') {
          that.props.history.push('/register', profile)
        } else if (json.body.m === 'ee' && json.body.s === 'c') {
          that.props.history.push('/barcode', profile)
        } else if (json.body.m === 'ee' && json.body.s === 'p') {
          that.props.history.push('/register', profile)
        } else {
          notification.open({
            message: 'Error happened',
            description: 'Please consult staff for help',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
        }
      })
      .catch(function(error) {
        console.log(error)
      });
  }

  componentClicked(response) {
    console.log(response);
  }

  googleSuccess(response) {
    const that = this
    const body = {
      platform: 'google',
      platformID: response.profileObj.googleId,
      profile: response.profileObj,
      email: response.profileObj.email,
      id: this.state.id
    }
    fetch(`${SERVER_URL}/login`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then((json) => {
        const profile = { i: json.body.i, platform: 'Google', id: this.state.id }
        if (json.body.m === 'pne') {
          that.props.history.push('/register', profile)
        } else if (json.body.m === 'pe' && json.body.s === 'c') {
          that.props.history.push('/barcode', profile)
        } else if (json.body.m === 'pe' && json.body.s === 'p') {
          that.props.history.push('/register', profile)
        } else if (json.body.m === 'ee' && json.body.s === 'c') {
          that.props.history.push('/barcode', profile)
        } else if (json.body.m === 'ee' && json.body.s === 'p') {
          that.props.history.push('/register', profile)
        } else {
          notification.open({
            message: 'Error happened',
            description: 'Please consult staff for help',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
        }
      })
      .catch(function(error) {
        console.log(error)
      });
  }

  googleFailure(response) {
    console.log(response);
  }

  render() {
    return (
      <div className="App">
        <header className="App-header backgroundLightGrey">
          <div className='card-header'>
            {/* <img className="logoImage" src="https://mtexpo.com.my/wp-content/uploads/2020/02/LOGO-2020-trans.png" alt="MTE logo" /> */}
            <Logo/>
            <DateAddress/>
          </div>
          <span className="title welcomeMessage">For manual registration, ﬁrst we need to verify your email address. Please check your email address for registration email. Your email will contain the link to continue with your registration.</span>
          <div className="border">
            <span className="smallTitle red bold">No Email User ID Found</span>
            <span className="smallSubtitle">We can't find your email ID in our system.</span>
            <Input
              className="emailInputClass"
              placeholder="Email 邮件"
              value={this.state.email}
              onChange={(event) => this.handleChange('email', event.target.value)}
            />
            <Button onClick={() => this.emailSignup()} className="nextButton">
              Sign Up with Email
            </Button>
            {/* <hr/>
            <span className="smallTitle">Quick Registration</span>
            <span className="smallSubtitle">Recommended way to sign up or login:</span>
            <FacebookLogin
              appId="1273656859894812"
              autoLoad={false}
              fields="name,email,picture"
              onClick={() => this.componentClicked}
              callback={(res) => this.responseFacebook(res)}
              cssClass="loginBtn loginBtn--facebook"
            />
            <GoogleLogin
              // clientId="1077503034509-fo825s8r9k5al8k3had4eigh40r7p4fh.apps.googleusercontent.com"
              clientId="115899716119-gd9hkfb5oh35dtvc8hrjklvq1qon7256.apps.googleusercontent.com"
              buttonText="Login"
              onSuccess={(response) => this.googleSuccess(response)}
              onFailure={(response) => this.googleFailure(response)}
              cookiePolicy={'single_host_origin'}
              render={renderProps => (
                <button onClick={renderProps.onClick} className="loginBtn loginBtn--google">
                  Login with Google
                </button>
              )}
            />
            <br/> */}
            <br/>
            <br/>
          </div>
          <br/>
          <br/>
        </header>
      </div>
    );
  }
}

export default EmailNotFound;
