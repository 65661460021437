import React from 'react';
import './../App.css';
import { Input, Button, Select, Checkbox, Modal } from 'antd';
// import { Option } from Select;
import { salutationList, LIST_3 } from './list';
import {SERVER_URL, MATCH_NON_ENGLISH, EMAIL_REG} from '../constant'
import { Alert, Spin } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import DateAddress from '../components/dateAddress';
import Logo from '../components/logo';
const { Option } = Select;
class InviteVIPP2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibitor: {},
      invalidLengthText: [],
      nonEnglishInputs: [],
      vipList: [],
      vip: {
        salutation: '',
        name: '',
        firstName: '',
        lastName: '',
        designation: '',
        company: '',
        email: '',
        telephone: '',
        profile: {
          'selected-3': [] // event shows
        },
        // uniqueVipId: ''
      },
      salutationRequired: false,
      nameRequired: false,
      companyRequired: false,
      designationRequired: false,
      emailRequired: false,
      emailExist: false,
      deleteList: [],
      invalidEmailFormat: false,
      selectedRow: null,
      isDelModalVisible: false,
      beforeunloadEvent: null,
      loading: false,
      localStorageListId: '',
      showReminder: true,
      showFormSendReminder: false,
      firstNameRequired: false,
      lastNameRequired: false,
      showsRequired: false
    };
  }

  componentDidMount() {
    if (localStorage.getItem('exhibitor') === null) {
      this.props.history.push('/vip')
      return
    }
    const exhibitor = localStorage.getItem('exhibitor')
    const object = JSON.parse(exhibitor)
    this.setState({'localStorageListId': 'vipList ' + object.company})

    const vipList = localStorage.getItem('vipList ' + object.company)

    if (exhibitor ) {
      this.setState({'exhibitor': JSON.parse(exhibitor)})
      // window.addEventListener("beforeunload", this.remindSubmit());
    }
    if (vipList) {
      this.setState({'vipList': JSON.parse(vipList)})
    }
    const event = window.addEventListener('beforeunload', (event) => {
      if (this.checkIsVIPList()) {
       event.preventDefault(); // for Firefox
       event.returnValue = 'This form had not been submitted, please click submit button to submit.'; // for Chrome
       return 'This form had not been submitted, please click submit button to submit.';
      }
      return false;
     });
     this.setState({"beforeunloadEvent": event})
  }

  handleChange(field, value) {
    const data = this.state.vip
    data[field] = value
    this.setState({'vip': data})
    this.setState({[field + 'Required']: false})
    const match = MATCH_NON_ENGLISH.test(value)
    const array = this.state.nonEnglishInputs
    const index = array.indexOf(field)
    const array2 = this.state.invalidLengthText
    const index2 = array2.indexOf(field)
    if ( index >= 0 && !match) {
      array.splice(index, 1)
      this.setState({'nonEnglishInputs': array})
    } else if (index < 0 && match) {
      array.push(field)
      this.setState({'nonEnglishInputs': array})
    }

    if (index2 >= 0 && (( value.length >= 1 && field !== 'firstName' && field !== 'lastName') || (value.length >= 3 && (field === 'firstName' || field === 'lastName')))) {
      array2.splice(index2, 1)
      this.setState({'invalidLengthText': array2})
    } else if (index2 < 0 && value.length > 0 && ((value.length < 1 && field !== 'firstName' && field !== 'lastName') || (value.length < 3 && (field === 'firstName' || field === 'lastName')))) {
      array2.push(field)
      this.setState({'invalidLengthText': array2})
    }

    if (field === 'email') {
      const invalidEmailFormat = EMAIL_REG.test(value)
      const emailIndex = this.state.vipList.findIndex(v => {
        return v.email === this.state.vip.email
      })
      if (emailIndex >= 0) {
        this.setState({'emailExist': true})
      } else {
        this.setState({'emailExist': false})
      }
      if (!invalidEmailFormat) {
        this.setState({'invalidEmailFormat': true})
      }
      else {
        this.setState({'invalidEmailFormat': false})
      }
    }
  }

  checking() {
    let validData = true
    const emailIndex = this.state.vipList.findIndex(v => {
      return v.email === this.state.vip.email
    })
    this.setState({'showsRequired': false})
    if (!this.state.vip.salutation) {
      this.setState({'salutationRequired': true})
      validData = false
    }
    if (!this.state.vip.email || this.state.invalidEmailFormat || this.state.nonEnglishInputs.includes('email') || this.state.emailExist) {
      this.setState({'emailRequired': true})
      validData = false
    }
    if (!this.state.vip.company || this.state.nonEnglishInputs.includes('company') || this.state.invalidLengthText.includes('company')) {
      this.setState({'companyRequired': true})
      validData = false
    }
    if (!this.state.vip.designation || this.state.nonEnglishInputs.includes('designation')) {
      this.setState({'designationRequired': true})
      validData = false
    }
    if (!this.state.vip.firstName || this.state.nonEnglishInputs.includes('firstName') || this.state.invalidLengthText.includes('firstName')) {
      this.setState({'firstNameRequired': true})
      validData = false
    }
    if (this.state.vip.lastName && this.state.nonEnglishInputs.includes('lastName')) {
      this.setState({'lastNameRequired': true})
      validData = false
    }
    if (!this.state.vip.telephone) {
      this.setState({'telephoneRequired': true})
      validData = false
    }
    if (this.state.vip.profile && this.state.vip.profile['selected-3'].length === 0) {

      this.setState({'showsRequired': true})
      validData = false
    }
    return validData
  }

  addVIP() {
    const array = this.state.vipList
    let validData = this.checking()
    if (validData) {
      array.push(this.state.vip)
      this.setState({'vipList': array})
      localStorage.setItem(['vipList ' + this.state.exhibitor.company], JSON.stringify(array))
      this.clear()
    }
  }

  checkEmail() {

  }

  clear() {
    this.setState({vip: {
      salutation: '',
      name: '',
      company: '',
      email: '',
      telephone: '',
      profile: {
        'selected-3': []
      }
    }})
    this.setState({salutationRequired: false})
    this.setState({firstNameRequired: false})
    this.setState({companyRequired: false})
    this.setState({emailRequired: false})
    this.setState({telephoneRequired: false})
  }

  delete() {
    const delArray = this.state.deleteList
    delArray.forEach(item => {
      const vipList = this.state.vipList
      const index = this.state.vipList.findIndex(v => {
        return v.email === item
      })
      vipList.splice(index, 1)
      this.setState({'vipList': vipList})
      localStorage.setItem(['vipList ' + this.state.exhibitor.company], JSON.stringify(vipList))
    });
  }

  deleteRow = (e) => {
    const index = this.state.selectedRow
    const vipList = this.state.vipList
    // const index = this.state.vipList.findIndex(v => {
    //   return v.email === item
    // })
    vipList.splice(index, 1)
    this.setState({'vipList': vipList})
    localStorage.setItem(['vipList ' + this.state.exhibitor.company], JSON.stringify(vipList))
    this.setState({'isDelModalVisible': false})
  }

  submit() {
    if (this.state.vipList.length > 0) {
      this.setState({'loading': true})
      fetch(`${SERVER_URL}/webEntry/inviteVIP`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exhibitor: this.state.exhibitor,
          vipList: this.state.vipList
        }),
      }).then(async (res) => {
        const response = await res.json()
        this.setState({'loading': false})
        if (response.statusCode === 200) {
          // Modal.success({
          //   title: "Nomination successfully submitted. Kindly await for approval from Organiser. ",
          //   content: "Note:<br/>*Exhibitors are responsible to follow up with VIPs on the email invitation as it might be the wrong email or in junk/spam mail\"<br/>*Approval from Organiser is set at 3.00pm on daily basis. Any nomination from 3.00pm will be processed the following working day.",
          //   onOk: () => {
          //     localStorage.removeItem("exhibitor");
          //     localStorage.removeItem(this.state.localStorageListId);
          //     this.props.history.push('/vip')
          //   }
          // });
          this.setState({showFormSendReminder: true})
          this.setState({'vipList' : []})
          localStorage.setItem(['vipList ' + this.state.exhibitor.company], JSON.stringify([]))
        } else {
          Modal.error({
            title: "Request Fail",
            content: "Request fail, plese try again.",
          });
        }
      })
    } else {
      Modal.error({
        title: "Unable To Submit",
        content: "Please add a least 1 row to submit the form.",
      });
    }
  }

  displaySalutation(value) {
    const index = salutationList.findIndex(v => {
      return v.value === value
    })
    return (index > 0 ) ? salutationList[index].label : ''
  }

  handleCancel = (e) => {
    this.setState({'selectedRow': null})
    this.setState({'isDelModalVisible': false});
  };

  showDeleteModal(index) {
    this.setState({'selectedRow': index})
    this.setState({'isDelModalVisible': true});
  };

  componentDidUpdate = () => {
    // if (this.checkIsVIPList()) {
    //   window.onbeforeunload = () => true
    // } else {
    //   window.onbeforeunload = undefined
    // }
  }

  checkIsVIPList() {
    return this.state.vipList.length > 0 ? true : false
  }

  closeReminder = () => {
    this.setState({'showReminder': false})
  }

  closeFormSendReminder = () => {
    this.setState({'showFormSendReminder': false})
  }

  onOkFormSendReminder = () => {
    localStorage.removeItem("exhibitor");
    localStorage.removeItem(this.state.localStorageListId);
    this.props.history.push('/vip')
  }
  boxChecked(value ,field) {
    const data = this.state.vip.profile[field];
    let result = []
    if (data) {
      result = Object.keys(data).map((key) => data[key])
    }
    return result.includes(value) ? true : false
  }

  checkboxOnChangeArray(event, field) {
    const data = this.state.vip.profile[field] ? this.state.vip.profile[field] : []
    if (data.includes(event.target.value)) {
      const index = data.indexOf(event.target.value)
      data.splice(index, 1)
    } else {
      data.push(event.target.value)
      this.setState({'showsRequired': false})
    }
    this.setState((prevState) => ({
      vip: {
        ...prevState.vip,
        profile: {
          "selected-3": data
        }
      }
    }))
  }

  getCheckedEvent(list) {
    const event = [];
    if (list instanceof Array && list.length > 0) {
      for (const item of list) {
        const index = LIST_3.options.findIndex(v => {
          return v.value === item;
        })
        if (index >= 0) {
          event.push(LIST_3.options[index].label);
        }
      }
    }
    return event;
  }


  render() {
    return (
      <div className="App">
        <header className="App-header full-page backgroundLightGrey">
          <div className='card-header'>
            {/* <img className="logoImage mb-10" src={require('../image/logo.png')} alt="MTE logo" /> */}
            <Logo/>
            <DateAddress/>
          </div>
          <br/>
          {/* <span className="title welcomeMessage">For manual registration, ﬁrst we need to verify your email address. Please check your email address for registration email. Your email will contain the link to continue with your registration.</span> */}
          <div className="addVip">
            <div className="border">
              <span className="bigTitle">VIP Nomination Form</span>
              {/* <span className="smallSubtitleWithoutMarginBottom">Exhibitor Company Name:</span> */}
              {/* <span className="smallSubtitle">我们已将您的登录说明发送给：</span> */}
              <div className='inputClass2'>
                <Select
                  size="default"
                  placeholder='Select a salutation'
                  onChange={(value) => this.handleChange('salutation', value)}
                  className={"selectClass " + (this.state.salutationRequired === true ? 'error' : '')}
                  value={this.state.vip.salutation}
                  defaultValue=""
                >
                  {salutationList.map(function(salutation){
                    return <Option key={salutation.value} value={salutation.value}>{salutation.label}</Option>
                  })}
                  <Option value="" selected disabled hidden>Select a salutation</Option>
                </Select>
                <div className='inputField'>
                  <Input
                    className={(this.state.firstNameRequired && 'redPlaceholder')}
                    placeholder={(this.state.firstNameRequired ? 'First Name required' : 'First Name')}
                    value={this.state.vip.firstName}
                    onChange={(event) => this.handleChange('firstName', event.target.value)}
                  />
                  {((this.state.nonEnglishInputs.includes('firstName') || this.state.invalidLengthText.includes('firstName')) && this.state.firstNameRequired) && <span className='error-message'>{this.state.nonEnglishInputs.includes('firstName') ? 'Please enter in english.': 'First Name too short! Please Enter real first name.'}</span>}
                </div>
                <div className='inputField'>
                  <Input
                    className={(this.state.lastNameRequired && 'redPlaceholder')}
                    placeholder={'Last Name'}
                    value={this.state.vip.lastName}
                    onChange={(event) => this.handleChange('lastName', event.target.value)}
                  />
                  {((this.state.nonEnglishInputs.includes('lastName') || this.state.invalidLengthText.includes('lastName')) && this.state.firstNameRequired) && <span className='error-message'>{this.state.nonEnglishInputs.includes('lastName') ? 'Please enter in english.': 'Last Name too short! Please Enter real last name.'}</span>}
                </div>
              </div>
              <div className="inputClass">
                <Input
                  className={(this.state.designationRequired && 'redPlaceholder')}
                  placeholder={(this.state.designationRequired ? 'Designation required' : 'Designation')}
                  value={this.state.vip.designation}
                  onChange={(event) => this.handleChange('designation', event.target.value)}
                />
                {((this.state.nonEnglishInputs.includes('designation') || this.state.invalidLengthText.includes('designation')) && this.state.designationRequired) && <span className='error-message'>{this.state.nonEnglishInputs.includes('designation') ? 'Please enter in english.': 'Designation too short. Please enter your real designation.'}</span>}
              </div>
              <div className='inputClass'>
                <Input
                  className={(this.state.companyRequired && 'redPlaceholder')}
                  placeholder={(this.state.telephoneRequired ? 'Company required' : 'Company')}
                  value={this.state.vip.company}
                  onChange={(event) => this.handleChange('company', event.target.value)}
                />
                {((this.state.nonEnglishInputs.includes('company') || this.state.invalidLengthText.includes('company')) && this.state.companyRequired) && <span className='error-message'>{this.state.nonEnglishInputs.includes('company') ? 'Please enter in english.': 'Company name too short! Please enter your full company name.'}</span>}
              </div>
              <div className='inputClass'>
                <Input
                  className={"inputClass " + (this.state.emailRequired && 'redPlaceholder')}
                  placeholder={(this.state.emailRequired ? 'Email required' : 'Email')}
                  value={this.state.vip.email}
                  onChange={(event) => this.handleChange('email', event.target.value)}
                  onBlur={(event) => this.checkEmail(event.target.value, 'emailExists')}
                />
                {((this.state.emailExist || this.state.invalidEmailFormat) && this.state.emailRequired) && <span className='error-message'>{this.state.invalidEmailFormat? 'Invalid Email Format.': 'This email already included in list.'}</span>}
              </div>
              <div className='inputClass'>
                <Input
                  className={"inputClass " + (this.state.telephoneRequired && 'redPlaceholder')}
                  placeholder={(this.state.telephoneRequired ? 'Phone required' : 'Phone')}
                  value={this.state.vip.telephone}
                  onChange={(event) => this.handleChange('telephone', event.target.value)}
                  type='number'
                />
                {(this.state.telephoneRequired) && <span className='error-message'>{this.state.invalidPhoneFormat? 'Invalid Phone Format.': 'This phone already included in list.'}</span>}
              </div>
              <div className='inputClass'>
                <div className="profile-content">
                  <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                    <span>Which shows to visit?</span>
                    <div>
                      <span className="selectSpan">Select one or more</span>
                    </div>
                  </div>
                  {
                    this.state.showsRequired && <div className='invalid-text'>
                      Please select at least one shows
                    </div>
                  }
                  {
                    LIST_3.categoryOptions && LIST_3.multiOption &&
                    <div className="profile-content" style={{marginTop: '10px'}}>
                      <div style={{display: 'flex', gap: '20px', alignItems: 'center', paddingLeft: '10px'}}>
                        { LIST_3.options.map((eventName, n) => (
                          <div key={'selected-3-' + n}>
                            <Checkbox key={'selected-3-' + n} className="checkboxClass" value={eventName.key} checked={this.boxChecked(eventName.key ,'selected-3')} onChange={(event) => this.checkboxOnChangeArray(event, 'selected-3',)} ><span className="checkboxSpan" style={{ fontWeight: 600}}>{eventName.key}</span></Checkbox>
                          </div>
                        ))}
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="buttons-area">
                <Button onClick={() => this.addVIP()} className="nextButton" >
                  Add
                </Button>
              </div>
            </div>
            <div className='side-buttons'>

              {/* <Button onClick={() => this.delete()} className={"deleteButton " + ((this.state.deleteList.length <= 0) && 'disable-btn')} disabled={(this.state.deleteList.length <= 0) ? true : false}>
                Delete
              </Button>
              <Button onClick={() => this.clear()} className="nextButton" >
                clear
              </Button>
              <Button onClick={() => this.submit()} className="nextButton" >
                Submit
              </Button> */}
            </div>
          </div>
          <div className='list-area'>
            <span className="bigTitle">VIP List</span>
            <div className="bottom-area">
              <div className="list-container header-row" key={'header'} id={'header'}>
                <div className='list-item'>Salutation</div>
                <div className='list-item'>First Name</div>
                <div className='list-item'>Last Name</div>
                <div className='list-item'>Email</div>
                <div className='list-item'>Designation</div>
                <div className='list-item'>company</div>
                <div className='list-item'>Shows</div>
                <div className='list-item'>Phone</div>
                <div className='list-item'></div>
              </div>
              {this.state.vipList.map((item, index) => (
                <div className="list-container" key={'vip-' + index} id={'vip-' + index}>
                  {/* <Checkbox key={item.email} className="checkboxClass list-item" value={item.email} checked={this.boxChecked(item.email ,'deleteList')} onChange={(event) => this.checkboxOnChangeArray(event, 'deleteList')}></Checkbox> */}
                  <div className='list-item' title={item.salutation}>{this.displaySalutation(item.salutation)}</div>
                  <div className='list-item' title={item.firstName}>{item.firstName}</div>
                  <div className='list-item' title={item.lastName}>{item.lastName}</div>
                  <div className='list-item' title={item.email}>{item.email}</div>
                  <div className='list-item' title={item.designation}>{item.designation}</div>
                  <div className='list-item' title={item.company}>{item.company}</div>
                  <div className='list-item'>{item.profile['selected-3'].join(', ')}</div>
                  <div className='list-item' title={item.telephone}>{item.telephone}</div>
                  <div className='list-item'>
                    <Button onClick={() => this.showDeleteModal(index)} className={"deleteButton"}>
                      Delete
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            { this.state.vipList.length > 0 && <div className="reminder"> Reminder: Once you have entered all the VIP details, you MUST click on the Submit button below to
            proceed, otherwise all information you entered will be LOST.</div>}
          </div>
          <div className='fix-bottom-area'>
            <Button onClick={() => this.submit()} className="nextButton" >
              SAVE AND SUBMIT
            </Button>
          </div>
        </header>
        <Modal title="Delete" visible={this.state.isDelModalVisible} onOk={this.deleteRow} onCancel={this.handleCancel}>
          <p>Are you sure you want to delete the selected row?</p>
        </Modal>
        <Modal title="NOTICE" visible={this.state.showReminder} onCancel={this.closeReminder} footer={[<Button key="understand" type="primary" onClick={this.closeReminder}>Understand</Button>]}>
          <p>Welcome to VIP Nomination Form. After entering all the VIP nomination contact details, please REMEMBER to click on the SUBMIT button at the bottom to proceed, otherwise all the entries will be LOST.</p>
        </Modal>
        <Modal visible={this.state.showFormSendReminder} onCancel={this.closeFormSendReminder} footer={[<Button key="Ok" type="primary" onClick={this.onOkFormSendReminder}>Ok</Button>]}>
          <div className="modal-body">
            <CheckCircleOutlined style={{ color: '#52c41a', fontSize: '24px', marginRight: '10px', marginTop: '5px' }} />
            <div>
              <p><b>Nomination successfully submitted. Kindly await for approval from Organiser.</b></p>
              <p><b>Note:</b></p>
              <ul className='modal-body-list'>
                <li>Exhibitors are responsible to follow up with VIPs on the email invitation as it might be the wrong email or in junk/spam mail.</li>
                <li>Approval from Organiser is set at 3.00pm on daily basis. Any nomination from 3.00pm will be processed the following working day.</li>
              </ul>
            </div>
          </div>
        </Modal>
        <div className={this.state.loading ? 'ant-modal-mask loading': 'hide'}>
          <Spin tip="Loading...">
          </Spin>
        </div>
      </div>
    );
  }
}

export default InviteVIPP2;
