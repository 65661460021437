import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './RouteWrapper';
import Login from '../pages/Login';
import Register from '../pages/Register_p1';
import Registerp2 from '../pages/Register_p2';
import BarcodePage from '../pages/BarcodePage';
import EmailVerification from '../pages/EmailVerification';
import EmailLogin from '../pages/EmailLogin';
import BarcodeEdit from '../pages/BarcodeEdit';
import EmailNotFound from '../pages/EmailNotFound';
import Update from '../pages/Update';
import InviteVipP1 from '../pages/InviteVIP_p1'
import InviteVipP2 from '../pages/InviteVIP_p2'

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/registerp2" component={Registerp2} />
      <Route path="/barcode" component={BarcodePage} />
      <Route path="/emailverification" component={EmailVerification} />
      <Route path="/emaillogin" component={EmailLogin} />
      <Route path="/barcodeedit" component={BarcodeEdit} />
      <Route path="/emailnotfound" component={EmailNotFound} />
      <Route path="/update" component={Update} />
      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      <Route path="/vip" component={InviteVipP1} />
      <Route path="/vipP2" component={InviteVipP2} />
      <Route component={Login} />
    </Switch>
  );
}