import React from 'react';
import './../App.css';
import queryString from 'query-string';
import { notification, Button } from 'antd';
import {SERVER_URL, MAP_LOCATION} from '../constant'
import DateAddress from '../components/dateAddress';
import Logo from '../components/logo';
class BarcodePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      barcode: '',
      name: '',
      email: '',
      designation: '',
      vType: 'Visitor',
      qrcode: '',
    };
  }

  navigateEdit(barcode) {
    const profile = {
      i: barcode,
    }
    this.props.history.push('/update', profile)
  }

  componentDidMount() {
    let i = ''
    const query = queryString.parse(this.props.location.search)
    if (Object.entries(query).length !== 0) {
      i = query.i
    } else if (this.props.location.state) {
      i = this.props.location.state.i
    }
    if (i !== '') {
      fetch(`${SERVER_URL}/visitor?i=${i}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(async (res) => {
          const response = await res.json()
          this.setState({barcode: response.body.key.toString()})
          this.setState({vType: response.body.vType})
          this.setState({qrcode: JSON.stringify({
            name: response.body.name,
            vType: response.body.vType,
            id: response.body.key,
            company: response.body.company,
          })})
          this.setState({name: response.body.name.toString()})
          this.setState({email: response.body.email.toString()})
          this.setState({designation: response.body.designation.toString()})
          window.scroll(0, 0);
        })
    } else {
      notification.open({
        message: 'No valid link / ID',
        description: 'Please go to your email and get the link',
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
    }
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <div className="backgroundLightGrey">
            <div className="card-header">
              {/* <img className="logoImage"  src={require('../image/logo.png')}  alt="MTE Logo"/> */}
              <Logo/>
              <DateAddress/>
            </div>
            <div className="sectionFirstBarcode">
              <div>
                <span className="fs34">Hi </span><span className="bold fs34">{this.state.name}</span>
              </div>
              <div>
                <div className="fs34">
                  <span className='fs26'>You have registered as </span><br></br>
                  <span className={this.state.vType}>{this.state.vType}</span>

                </div>
              </div>
              <br></br>
              <div>
                <span className="fs14">Scan the QR Code below at the <span className="bold">Visitor Registration Counter</span> to collect your admission badge. Read the instructions below to find out how.</span>
              </div>
              <br/>
              <div>
                <span className="fs14">We have sent a copy to your email: <br/><span className="bold">{this.state.email}</span>.</span>
              </div>
              <Button className="editButton" onClick={() => this.navigateEdit(this.state.barcode)}>
                <span className="bold fs16">VIEW / EDIT YOUR PROFILE</span>
              </Button>
            </div>
            <div className="sectionSecondBarcode">
              {this.state.qrcode &&
                <img className="barcode" alt="" src={`${SERVER_URL}/barcodeGenerate?bcid=qrcode&text=${encodeURI(this.state.qrcode)}`}/>
              }
              <span className="fs34">{this.state.barcode.toString().substring(0, 4)}-{this.state.barcode.toString().substring(4, 8)}</span>
            </div>
            <div className="sectionThirdBarcode">
              {/* <div className='header'>
                <span className="fs14">To ease your sourcing needs, we have made MTE 2023 show highlights available at <a href="https://www.expoworks.com.my/fpm/showpreview" target="_blank">https://www.expoworks.com.my/fpm/showpreview</a>.</span>
              </div> */}
              <img alt="" src="https://firebasestorage.googleapis.com/v0/b/tradelink-2e341.appspot.com/o/badge1.jpg?alt=media&token=f5ace1be-226b-42f8-83a0-cc6c8c8f1ee2"/>
              <img alt="" src="https://firebasestorage.googleapis.com/v0/b/tradelink-2e341.appspot.com/o/badge2.jpg?alt=media&token=02fcb09f-09fd-4f9a-b8f2-472f28923986"/>
              <img alt="" src="https://firebasestorage.googleapis.com/v0/b/tradelink-2e341.appspot.com/o/badge4.jpg?alt=media&token=f63f24c4-59cc-439e-b90a-32ccfde1ba06"/>
              <img alt="" src="https://firebasestorage.googleapis.com/v0/b/tradelink-2e341.appspot.com/o/badge3.jpg?alt=media&token=43c025d5-9d94-45cb-b896-527657f2e0d9"/>
              <Button className="editButton" onClick={() => window.open('https://mte365.mtexpo.com.my/home')}>
                <span className="bold fs16">View Exhibit Highlights Here</span>
              </Button>
              <div>
                <span className="fs34 red">How to Collect Your Badge</span>
              </div>
              <span className="instructionTitle">Badge Collection Instructions</span>
              <ol>
                <li className="instruction">Please print out this confirmation email or open this email on your mobile phone with the bar code visible to collect your visitor admission badge and lanyard. <br/> Proceed to ANY Visitor Registration Counter located at the exhibition hall to print your visitor admission badge.</li>
                <li className="instruction">Click <a href={MAP_LOCATION} target="_blank">here</a> to view the location map of the exhibition hall.</li>
                <li className="instruction">Scan the bar code from any of the available booth and wait for the badges to be printed.</li>
                <li className="instruction">Collect your lanyard from the counter staff and clip it around your neck.</li>
              </ol>
              <span className="instructionTitle">Admission Guide</span>
              <ul>
                <li className="instruction disc">You are required to be in proper business attire.</li>
                <li className="instruction disc">The Organiser reserves the right to refuse entry to those deemed inappropriately dressed e.g. in bermudas, shorts, sports sandals, slippers, flip-flops, etc.</li>
                <li className="instruction disc">Admission is restriction to trade and business professionals only. </li>
                <li className="instruction disc">General public and minors below the age of 18 will not be allowed entry.</li>
                <li className="instruction disc">Visitor badges are non-transferrable due to security reasons. You may be asked by the security personnel to present proof of identification before entry to the exhibition halls.</li>
                <li className="instruction disc">Badges can only be printed once per day. If you lost your badge, please proceed to the registration counter and seek further assistance from our staff to reprint your badge.</li>
              </ul>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default BarcodePage;
