import React from 'react';

function Logo(props) {
  return (
    <div>
      <div className='row logos'>
        <a>
          <div className='logoImage-1'></div>
        </a>
        {/* <a href='https://www.malt-expo.com.my/' target='_blank'>
          <div className='logoImage-1'></div>
        </a> */}
        {/* <img className="logoImage mb-10" src={require('../image/logo1.png')}alt="MTE logo" />
        <img className="logoImage mb-10" src={require('../image/logo2.png')}alt="MTE logo" /> */}
      </div>
      <div className='row logos'>
        <img className='small-logo'  src={require('../image/Pack Expo Logo (Round).png')}  loading='lazy' alt="Pack Expo Logo" />
        <img className='small-logo' src={require('../image/SF LOGO-01.jpg')} loading='lazy' alt="SF Logo"/>
        <img className='small-logo' src={require('../image/MTE logo (Black Words).png')} loading='lazy' alt="MTE Logo" />
        <img className='small-logo' src={require('../image/MALT LOGO-01.jpg')} loading='lazy' alt="MALT Logo"/>
      </div>
    </div>
  );
}

export default Logo;