const famousList = [
  {name: 'Malaysia', code: 'MY'},
  {name: 'Singapore', code: 'SG'},
  {name: 'China', code: 'CN'},
  {name: 'Taiwan', code: 'TW'},
  {name: 'Thailand', code: 'TH'},
]
const countryList = [
  {name: 'Afghanistan', code: 'AF'},
  {name: 'Åland Islands', code: 'AX'},
  {name: 'Albania', code: 'AL'},
  {name: 'Algeria', code: 'DZ'},
  {name: 'American Samoa', code: 'AS'},
  {name: 'AndorrA', code: 'AD'},
  {name: 'Angola', code: 'AO'},
  {name: 'Anguilla', code: 'AI'},
  {name: 'Antarctica', code: 'AQ'},
  {name: 'Antigua and Barbuda', code: 'AG'},
  {name: 'Argentina', code: 'AR'},
  {name: 'Armenia', code: 'AM'},
  {name: 'Aruba', code: 'AW'},
  {name: 'Australia', code: 'AU'},
  {name: 'Austria', code: 'AT'},
  {name: 'Azerbaijan', code: 'AZ'},
  {name: 'Bahamas', code: 'BS'},
  {name: 'Bahrain', code: 'BH'},
  {name: 'Bangladesh', code: 'BD'},
  {name: 'Barbados', code: 'BB'},
  {name: 'Belarus', code: 'BY'},
  {name: 'Belgium', code: 'BE'},
  {name: 'Belize', code: 'BZ'},
  {name: 'Benin', code: 'BJ'},
  {name: 'Bermuda', code: 'BM'},
  {name: 'Bhutan', code: 'BT'},
  {name: 'Bolivia', code: 'BO'},
  {name: 'Bosnia and Herzegovina', code: 'BA'},
  {name: 'Botswana', code: 'BW'},
  {name: 'Bouvet Island', code: 'BV'},
  {name: 'Brazil', code: 'BR'},
  {name: 'British Indian Ocean Territory', code: 'IO'},
  {name: 'Brunei Darussalam', code: 'BN'},
  {name: 'Bulgaria', code: 'BG'},
  {name: 'Burkina Faso', code: 'BF'},
  {name: 'Burundi', code: 'BI'},
  {name: 'Cambodia', code: 'KH'},
  {name: 'Cameroon', code: 'CM'},
  {name: 'Canada', code: 'CA'},
  {name: 'Cape Verde', code: 'CV'},
  {name: 'Cayman Islands', code: 'KY'},
  {name: 'Central African Republic', code: 'CF'},
  {name: 'Chad', code: 'TD'},
  {name: 'Chile', code: 'CL'},
  {name: 'Christmas Island', code: 'CX'},
  {name: 'Cocos (Keeling) Islands', code: 'CC'},
  {name: 'Colombia', code: 'CO'},
  {name: 'Comoros', code: 'KM'},
  {name: 'Congo', code: 'CG'},
  {name: 'Congo, The Democratic Republic of the', code: 'CD'},
  {name: 'Cook Islands', code: 'CK'},
  {name: 'Costa Rica', code: 'CR'},
  {name: 'Cote D\'Ivoire', code: 'CI'},
  {name: 'Croatia', code: 'HR'},
  {name: 'Cuba', code: 'CU'},
  {name: 'Cyprus', code: 'CY'},
  {name: 'Czech Republic', code: 'CZ'},
  {name: 'Denmark', code: 'DK'},
  {name: 'Djibouti', code: 'DJ'},
  {name: 'Dominica', code: 'DM'},
  {name: 'Dominican Republic', code: 'DO'},
  {name: 'Ecuador', code: 'EC'},
  {name: 'Egypt', code: 'EG'},
  {name: 'El Salvador', code: 'SV'},
  {name: 'Equatorial Guinea', code: 'GQ'},
  {name: 'Eritrea', code: 'ER'},
  {name: 'Estonia', code: 'EE'},
  {name: 'Ethiopia', code: 'ET'},
  {name: 'Falkland Islands (Malvinas)', code: 'FK'},
  {name: 'Faroe Islands', code: 'FO'},
  {name: 'Fiji', code: 'FJ'},
  {name: 'Finland', code: 'FI'},
  {name: 'France', code: 'FR'},
  {name: 'French Guiana', code: 'GF'},
  {name: 'French Polynesia', code: 'PF'},
  {name: 'French Southern Territories', code: 'TF'},
  {name: 'Gabon', code: 'GA'},
  {name: 'Gambia', code: 'GM'},
  {name: 'Georgia', code: 'GE'},
  {name: 'Germany', code: 'DE'},
  {name: 'Ghana', code: 'GH'},
  {name: 'Gibraltar', code: 'GI'},
  {name: 'Greece', code: 'GR'},
  {name: 'Greenland', code: 'GL'},
  {name: 'Grenada', code: 'GD'},
  {name: 'Guadeloupe', code: 'GP'},
  {name: 'Guam', code: 'GU'},
  {name: 'Guatemala', code: 'GT'},
  {name: 'Guernsey', code: 'GG'},
  {name: 'Guinea', code: 'GN'},
  {name: 'Guinea-Bissau', code: 'GW'},
  {name: 'Guyana', code: 'GY'},
  {name: 'Haiti', code: 'HT'},
  {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
  {name: 'Holy See (Vatican City State)', code: 'VA'},
  {name: 'Honduras', code: 'HN'},
  {name: 'Hong Kong', code: 'HK'},
  {name: 'Hungary', code: 'HU'},
  {name: 'Iceland', code: 'IS'},
  {name: 'India', code: 'IN'},
  {name: 'Indonesia', code: 'ID'},
  {name: 'Iran, Islamic Republic Of', code: 'IR'},
  {name: 'Iraq', code: 'IQ'},
  {name: 'Ireland', code: 'IE'},
  {name: 'Isle of Man', code: 'IM'},
  {name: 'Israel', code: 'IL'},
  {name: 'Italy', code: 'IT'},
  {name: 'Jamaica', code: 'JM'},
  {name: 'Japan', code: 'JP'},
  {name: 'Jersey', code: 'JE'},
  {name: 'Jordan', code: 'JO'},
  {name: 'Kazakhstan', code: 'KZ'},
  {name: 'Kenya', code: 'KE'},
  {name: 'Kiribati', code: 'KI'},
  {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
  {name: 'Korea, Republic of', code: 'KR'},
  {name: 'Kuwait', code: 'KW'},
  {name: 'Kyrgyzstan', code: 'KG'},
  {name: 'Lao People\'S Democratic Republic', code: 'LA'},
  {name: 'Latvia', code: 'LV'},
  {name: 'Lebanon', code: 'LB'},
  {name: 'Lesotho', code: 'LS'},
  {name: 'Liberia', code: 'LR'},
  {name: 'Libyan Arab Jamahiriya', code: 'LY'},
  {name: 'Liechtenstein', code: 'LI'},
  {name: 'Lithuania', code: 'LT'},
  {name: 'Luxembourg', code: 'LU'},
  {name: 'Macao', code: 'MO'},
  {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
  {name: 'Madagascar', code: 'MG'},
  {name: 'Malawi', code: 'MW'},
  {name: 'Maldives', code: 'MV'},
  {name: 'Mali', code: 'ML'},
  {name: 'Malta', code: 'MT'},
  {name: 'Marshall Islands', code: 'MH'},
  {name: 'Martinique', code: 'MQ'},
  {name: 'Mauritania', code: 'MR'},
  {name: 'Mauritius', code: 'MU'},
  {name: 'Mayotte', code: 'YT'},
  {name: 'Mexico', code: 'MX'},
  {name: 'Micronesia, Federated States of', code: 'FM'},
  {name: 'Moldova, Republic of', code: 'MD'},
  {name: 'Monaco', code: 'MC'},
  {name: 'Mongolia', code: 'MN'},
  {name: 'Montserrat', code: 'MS'},
  {name: 'Morocco', code: 'MA'},
  {name: 'Mozambique', code: 'MZ'},
  {name: 'Myanmar', code: 'MM'},
  {name: 'Namibia', code: 'NA'},
  {name: 'Nauru', code: 'NR'},
  {name: 'Nepal', code: 'NP'},
  {name: 'Netherlands', code: 'NL'},
  {name: 'Netherlands Antilles', code: 'AN'},
  {name: 'New Caledonia', code: 'NC'},
  {name: 'New Zealand', code: 'NZ'},
  {name: 'Nicaragua', code: 'NI'},
  {name: 'Niger', code: 'NE'},
  {name: 'Nigeria', code: 'NG'},
  {name: 'Niue', code: 'NU'},
  {name: 'Norfolk Island', code: 'NF'},
  {name: 'Northern Mariana Islands', code: 'MP'},
  {name: 'Norway', code: 'NO'},
  {name: 'Oman', code: 'OM'},
  {name: 'Pakistan', code: 'PK'},
  {name: 'Palau', code: 'PW'},
  {name: 'Palestinian Territory, Occupied', code: 'PS'},
  {name: 'Panama', code: 'PA'},
  {name: 'Papua New Guinea', code: 'PG'},
  {name: 'Paraguay', code: 'PY'},
  {name: 'Peru', code: 'PE'},
  {name: 'Philippines', code: 'PH'},
  {name: 'Pitcairn', code: 'PN'},
  {name: 'Poland', code: 'PL'},
  {name: 'Portugal', code: 'PT'},
  {name: 'Puerto Rico', code: 'PR'},
  {name: 'Qatar', code: 'QA'},
  {name: 'Reunion', code: 'RE'},
  {name: 'Romania', code: 'RO'},
  {name: 'Russian Federation', code: 'RU'},
  {name: 'RWANDA', code: 'RW'},
  {name: 'Saint Helena', code: 'SH'},
  {name: 'Saint Kitts and Nevis', code: 'KN'},
  {name: 'Saint Lucia', code: 'LC'},
  {name: 'Saint Pierre and Miquelon', code: 'PM'},
  {name: 'Saint Vincent and the Grenadines', code: 'VC'},
  {name: 'Samoa', code: 'WS'},
  {name: 'San Marino', code: 'SM'},
  {name: 'Sao Tome and Principe', code: 'ST'},
  {name: 'Saudi Arabia', code: 'SA'},
  {name: 'Senegal', code: 'SN'},
  {name: 'Serbia and Montenegro', code: 'CS'},
  {name: 'Seychelles', code: 'SC'},
  {name: 'Sierra Leone', code: 'SL'},
  {name: 'Slovakia', code: 'SK'},
  {name: 'Slovenia', code: 'SI'},
  {name: 'Solomon Islands', code: 'SB'},
  {name: 'Somalia', code: 'SO'},
  {name: 'South Africa', code: 'ZA'},
  {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
  {name: 'Spain', code: 'ES'},
  {name: 'Sri Lanka', code: 'LK'},
  {name: 'Sudan', code: 'SD'},
  {name: 'Suriname', code: 'SR'},
  {name: 'Svalbard and Jan Mayen', code: 'SJ'},
  {name: 'Swaziland', code: 'SZ'},
  {name: 'Sweden', code: 'SE'},
  {name: 'Switzerland', code: 'CH'},
  {name: 'Syrian Arab Republic', code: 'SY'},
  {name: 'Tajikistan', code: 'TJ'},
  {name: 'Tanzania, United Republic of', code: 'TZ'},
  {name: 'Timor-Leste', code: 'TL'},
  {name: 'Togo', code: 'TG'},
  {name: 'Tokelau', code: 'TK'},
  {name: 'Tonga', code: 'TO'},
  {name: 'Trinidad and Tobago', code: 'TT'},
  {name: 'Tunisia', code: 'TN'},
  {name: 'Turkey', code: 'TR'},
  {name: 'Turkmenistan', code: 'TM'},
  {name: 'Turks and Caicos Islands', code: 'TC'},
  {name: 'Tuvalu', code: 'TV'},
  {name: 'Uganda', code: 'UG'},
  {name: 'Ukraine', code: 'UA'},
  {name: 'United Arab Emirates', code: 'AE'},
  {name: 'United Kingdom', code: 'GB'},
  {name: 'United States', code: 'US'},
  {name: 'United States Minor Outlying Islands', code: 'UM'},
  {name: 'Uruguay', code: 'UY'},
  {name: 'Uzbekistan', code: 'UZ'},
  {name: 'Vanuatu', code: 'VU'},
  {name: 'Venezuela', code: 'VE'},
  {name: 'Viet Nam', code: 'VN'},
  {name: 'Virgin Islands, British', code: 'VG'},
  {name: 'Virgin Islands, U.S.', code: 'VI'},
  {name: 'Wallis and Futuna', code: 'WF'},
  {name: 'Western Sahara', code: 'EH'},
  {name: 'Yemen', code: 'YE'},
  {name: 'Zambia', code: 'ZM'},
  {name: 'Zimbabwe', code: 'ZW'}
]

const piList = [
  {value: 1, label: 'Abrasives 研磨材料'},
  {value: 2, label: 'Control Technology 电力传动，气动，水压及液压控制技术'},
  {value: 4, label: 'Coolants / Lubricants 冷却剂 / 润滑剂'},
  {value: 8, label: 'Design Software, CAD / CAM / CAE, Rapid Prototyping 设计软件快速原型制造'},
  {value: 16, label: 'EDM & Wire-Cut Machines 放电加工机及线割加工机'},
  {value: 32, label: 'Flame or Plasma Cutting Equipment 火焰或电浆切割仪器'},
  {value: 64, label: 'Forming & Fabricating Machines (Stamping, Bending, Shearing) 钣金机（冲床压床拆床剪床)'},
  {value: 128, label: 'Grinding & Finishing Machinery 磨床'},
  {value: 256, label: 'Industrial Lasers, Laser Systems / Machines & Waterjet 镭射'},
  {value: 512, label: 'Cutting Systems / Machines 工业用激光系统'},
  {value: 1024, label: 'Jewellery Making Machines 珠宝制作机械'},
  {value: 2048, label: 'Turning Centers & Lathes 车削机及车床'},
  {value: 4096, label: 'Machining Centers 综合加工机'},
  {value: 8192, label: 'Machine Tool Accessories, Components, Attachments 机床零组件'},
  {value: 16384, label: 'Manufacturing Automation / Machinery & Equipments 自动化制造 / 机械及配备'},
  {value: 32768, label: 'Materials Handling / Storage / Warehousing 材料处理 / 储存 / 库存'},
  {value: 65536, label: 'Measuring, Testing & Control, Quality Assurance 量度检查及测试 / 质量监控'},
  {value: 131072, label: 'Milling, Drilling or Boring Machines 铣床钻床及搪床'},
  {value: 262144, label: 'Power Transmission, Pneumatics, Hydraulics & Fluid'},
  {value: 1048576, label: 'Robots & Vision Automation 自动化机器及视像'},
  {value: 2097152, label: 'Saws & Saw Blades 锯床'},
  {value: 4194304, label: 'Tools & Tooling 工具'},
  {value: 8388608, label: 'Welding & Joining Equipment 焊接机械'},
  {value: 16777216, label: 'Wire Forming & Coil Processing Equipment 绞线床及弹簧制造机'},
  {value: 33554432, label: 'Others 其他'},
]

const siList = [
  {value: 2, label: 'Assembly & Sub-Assembly 组装及部件组装'},
  {value: 4, label: 'Electroplating 电镀'},
  {value: 8, label: 'Heat Treatment, Furnace 热处理系统 / F工业用火炉'},
  {value: 16, label: 'Jigs & Fixtures 夹具及治具'},
  {value: 32, label: 'Laser Cutting & Marking 激光切割及打印'},
  {value: 64, label: 'Machining & Tooling Services 金属加工及工具制造'},
  {value: 128, label: 'Metal Parts, Products & Components Manufacturing 金属零部件及产品制造'},
  {value: 256, label: 'Mould & Die Design / Fabrication 压铸件设计及制造'},
  {value: 512, label: 'Plastic & Rubber Products Manufacturing 塑胶产品制造'},
  {value: 1024, label: 'Prototyping / Rapid Tooling 快速原形制造'},
  {value: 2048, label: 'Secondary Process - Tapping, Spot Welding, Silk-Screening 第二阶段加工 - 螺丝点焊'},
  {value: 4096, label: 'Semiconductor & Leadframe Manufacturing 半导体 / 高精密部件制造'},
  {value: 8192, label: 'Stamping / Forming / Sheet Metal Fabrication 饭金加工制造 （冲压折弯）'},
  {value: 16384, label: 'Surface Finishing 磨光处理'},
  {value: 32768, label: 'Turnkey Projects / Box-Build 整件承包生产'},
  {value: 65536, label: 'Others 其他'}
]

const mjList = [
{value: 2, label: 'Air-conditioning & Refrigeration Industry 空调和制冷行业'},
{value: 4, label: 'Aerospace Manufacturing 航空制造业'},
{value: 8, label: 'Automotive Manufacturing 汽车制造业'},
{value: 16, label: 'Bio-medical Products & Equipment Manufacturing 医疗设备制造业'},
{value: 32, label: 'Building & Construction 建筑及建设工程'},
{value: 64, label: 'Chemicals 化学制品'},
{value: 128, label: 'Cosmetics 化妆品'},
{value: 256, label: 'Consultancy Service 咨询服务'},
{value: 512, label: 'Defence 防卫'},
{value: 1024, label: 'Electronics & Electrical Manufacturing 电子及电器制造业'},
{value: 2048, label: 'Environment & Pollution Control 环境与污染控制'},
{value: 4096, label: 'Food & Beverage Processing 食品及饮料加工'},
{value: 8192, label: 'Furniture Manufacturing 家俱制造业'},
{value: 16384, label: 'Industrial Consumables 工业消耗品'},
{value: 32768, label: 'Jewellery 首饰'},
{value: 65536, label: 'Machining Services 金属加工'},
{value: 131072, label: 'Machinery & Equipment 机械及设备制造'},
{value: 262144, label: 'Manufacturer 制造业'},
{value: 524288, label: 'Marine / Shipbuilding & Repair 造船及维修 / 海洋工业'},
{value: 1048576, label: 'Metal Parts & Components Manufacturing 金属零部件制造'},
{value: 2097152, label: 'Mould & Die Manufacturing / Tooling 模具制造'},
{value: 4194304, label: 'Oil & Gas / Chemical Processing 石油化工业'},
{value: 8388608, label: 'Organiser, Exhibition / Convention 展览及会议主办公司'},
{value: 16777216, label: 'Government 政府'},
{value: 33554432, label: 'OEM / Subcontractor 代工 / 转包商'},
{value: 67108864, label: 'Packaging 包装'},
{value: 134217728, label: 'Petrochemical 石油化业'},
{value: 268435456, label: 'Pharmaceutical / Medical 制药业 / 医疗'},
{value: 536870912, label: 'Plastic & Rubber Products Manufacturing 塑胶产品制造'},
{value: 1073741824, label: 'Pulp & Paper 纸浆及造纸业'},
{value: 2147483648, label: 'Research & Development 研究及发展'},
{value: 4294967296, label: 'Rubber 橡胶'},
{value: 8589934592, label: 'Sawmill 锯木业'},
{value: 17179869184, label: 'Semiconductor & Precision Engineering 半导体及高精密部件制造'},
{value: 34359738368, label: 'Test and Inspection & Quality Control 测试检验及质量控制'},
{value: 68719476736, label: 'Trade Associations / Government Agency 工商协会 / 政府部门'},
{value: 137438953472, label: 'Trade / Agent / Distributor 贸易 / 代理 / 分销商'},
{value: 274877906944, label: 'Training Institutions / Academia 培训机构 / 教育界'},
{value: 549755813888, label: 'Others 其他'},
]

const pjList = [
  {value: 2, label: 'Academia / Lecturer / Trainer 学术研究 / 讲师 / 教练'},
  {value: 4, label: 'Chairman / Director / Owner / MD / GM 主席 / 行政总裁 / 董事长 / 总经理'},
  {value: 8, label: 'Contractor / Subcontractor 承包商 / 转包商'},
  {value: 16, label: 'Design / R&D 设计 / 研发'},
  {value: 32, label: 'Distributor /Agent / Importer / Trader 经销商 / 代理 / 进口商 / 贸易商'},
  {value: 64, label: 'Engineer / Technician 工程师 / 技术人员'},
  {value: 128, label: 'Government Official 政府人员 / 公务员'},
  {value: 256, label: 'Maintenance / Factory Management 维修 / 工厂管理'},
  {value: 512, label: 'Media / Editor / Journalist 传媒 / 编辑 / 记者'},
  {value: 1024, label: 'Production / Operations 生产 / 营运'},
  {value: 2048, label: 'Purchasing / Specifying / Sourcing 采购'},
  {value: 4096, label: 'Quality Control / Assurance / Testing & Inspection 质量监控 / 保用 / 测试及检查'},
  {value: 8192, label: 'Sales & Marketing 行销及市场推广'},
  {value: 16384, label: 'State Enterprises 国营企业'},
  {value: 32768, label: 'Others 其他'}
]

const mpList = [
  {value: 1, label: 'To purchase 采购'},
  {value: 2, label: 'To recommend purchase of products 推荐产品'},
  {value: 4, label: 'To establish new distribution channels / agents 寻找新供应商 / 代理'},
  {value: 8, label: 'To evaluate show for future participation 为日后的参与进行评估'},
  {value: 16, label: 'To meet suppliers / buyers 会见供应商 / 买家'},
  {value: 32, label: 'To update & gather technology & market information 搜集最新市场及科技资讯'},
  {value: 64, label: 'Others 其他'},
]

const cList = [
  {value: 1, label: 'Billboard 大型广告牌'},
  {value: 2, label: 'Brochures / Letters sent by exhibitors of this show 由参展商寄出的宣传单'},
  {value: 4, label:  'Direct Mail Invitation from show organiser 直邮邀请'},
  {value: 8, label: 'Email Newsletters / Website 电邮商讯 / 网站'},
  {value: 16, label: 'Friends / Colleagues recommendation 朋友 / 同事的推荐'},
  {value: 32, label: 'Newspapers 报章'},
  {value: 64, label: 'SMS 短信'},
  {value: 128, label: 'Trade Magazine 杂志'},
  {value: 256, label: 'Trade Association / Society, please specify 商贸组织，请注明'},
  {value: 512, label: 'Others 其他'}
]

const aList = [
  {value: 1, label: 'No involvement 没有参与'},
  {value: 2, label: 'Decision Maker / Final Authority 决策者 / 最终决定的权力'},
  {value: 4, label: 'Recommender / Influencer 推荐 / 影响决策者'},
]

const ppList = [
  {value: 1, label: 'Q1 (Jan-Mar) 第一季（一月至三月份）'},
  {value: 2, label: 'Q2 (Apr-Jun) 第二季（四月至六月份）'},
  {value: 4, label: 'Q3 (Jul-Sept) 第三季（七月至九月份）'},
  {value: 8, label: 'Q4 (Oct-Dec) 第四季（十月至十二月份）'},
]

const pbList = [
  {value: 1, label: 'Less than MYR 1,000,000 低于马币一百万'},
  {value: 2, label: 'MYR 1,000,000-5,000,000 马币一百万至五百万'},
  {value: 4, label: 'MYR 5,000,000-10,000,000 马币五百万至一千万'},
  {value: 8, label: 'MYR 10,000,000 马币一千万'},
]

const salutationList = [
  {value: 'ms', label: 'Miss'},
  {value: 'mr', label: 'Mr'},
  {value: 'mrs', label: 'Mrs'},
  {value: 'dr', label: 'Dr'},
  // {value: 'drs', label: 'Drs'},
  {value: 'dato', label: 'Dato'},
  {value: 'datin', label: 'Datin'},
  {value: 'datin_sri', label: 'Datin Seri'},
  {value: 'datuk', label: 'Datuk'},
  {value: 'datuk_sri', label: 'Datuk Seri'},
  {value: 'tan_sri', label: 'Tan Sri'},
]

const INTERESTED_SHOW_CATEGORY = [
  {
    "category": "Automation",
    "subCategory": "Collaborative Robots",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Automation",
    "subCategory": "Control Technology",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Automation",
    "subCategory": "Drives Systems & Components",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Automation",
    "subCategory": "Sensor Technology",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Automation",
    "subCategory": "Industrial Robots",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Factory Equipment & Industrial Supplies",
    "subCategory": "Accessories, Components & Parts",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Factory Equipment & Industrial Supplies",
    "subCategory": "Air Delivery Solutions",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Factory Equipment & Industrial Supplies",
    "subCategory": "Bearings",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Factory Equipment & Industrial Supplies",
    "subCategory": "Cleaning Equipment, Chemicals",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Factory Equipment & Industrial Supplies",
    "subCategory": "Cooling & Lubrication",
    "availableIn": [
      "MTE",
      "SF"
    ]
  },
  {
    "category": "Factory Equipment & Industrial Supplies",
    "subCategory": "Fasteners",
    "availableIn": [
      "MTE",
      "MALT",
      "SF"
    ]
  },
  {
    "category": "Factory Equipment & Industrial Supplies",
    "subCategory": "Filters, Fittings & Hoses",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Factory Equipment & Industrial Supplies",
    "subCategory": "Flooring",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Factory Equipment & Industrial Supplies",
    "subCategory": "Heat Treatment Solutions",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Factory Equipment & Industrial Supplies",
    "subCategory": "HVAC",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Factory Equipment & Industrial Supplies",
    "subCategory": "Hydraulics",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Factory Equipment & Industrial Supplies",
    "subCategory": "Mechanical Infrastructure",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Factory Equipment & Industrial Supplies",
    "subCategory": "Pneumatics",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Factory Equipment & Industrial Supplies",
    "subCategory": "Power Tools",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Factory Equipment & Industrial Supplies",
    "subCategory": "Safety Product & Equipments",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Factory Equipment & Industrial Supplies",
    "subCategory": "Surface Finishing Solutions",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "General Services",
    "subCategory": "Government Agencies/Ministries",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "General Services",
    "subCategory": "Logistic Services & Outsourcing",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "General Services",
    "subCategory": "Repair & Maintenance",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "General Services",
    "subCategory": "Subcontracting & Outsourcing",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "General Services",
    "subCategory": "Trade Association",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "General Services",
    "subCategory": "Trade Publication",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "General Services",
    "subCategory": "Training & Certification",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Logistics Technology",
    "subCategory": "Auto-ID/IOT",
    "availableIn": [
      "MTE",
      "MALT",
      "SF"
    ]
  },
  {
    "category": "Logistics Technology",
    "subCategory": "Automated Guided Vehicle (AGV)",
    "availableIn": [
      "MTE",
      "MALT",
      "SF"
    ]
  },
  {
    "category": "Logistics Technology",
    "subCategory": "Automated Mobile Robot (AMR)",
    "availableIn": [
      "MTE",
      "MALT",
      "SF"
    ]
  },
  {
    "category": "Logistics Technology",
    "subCategory": "Automated Storage & Retrieval System (AS/RS)",
    "availableIn": [
      "MTE",
      "MALT",
      "SF"
    ]
  },
  {
    "category": "Logistics Technology",
    "subCategory": "Cold Chain Solutions",
    "availableIn": [
      "MTE",
      "MALT",
      "SF"
    ]
  },
  {
    "category": "Logistics Technology",
    "subCategory": "Conveyors",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Logistics Technology",
    "subCategory": "Industrial Casters",
    "availableIn": [
      "MTE",
      "MALT",
      "SF"
    ]
  },
  {
    "category": "Logistics Technology",
    "subCategory": "Lifting Equipment & Machinery",
    "availableIn": [
      "MTE",
      "MALT",
      "SF"
    ]
  },
  {
    "category": "Logistics Technology",
    "subCategory": "Material Handling Systems",
    "availableIn": [
      "MTE",
      "MALT",
      "SF"
    ]
  },
  {
    "category": "Logistics Technology",
    "subCategory": "Racking & Storage Equipment",
    "availableIn": [
      "MTE",
      "MALT",
      "SF"
    ]
  },
  {
    "category": "Logistics Technology",
    "subCategory": "Traffic Engineering",
    "availableIn": [
      "MTE",
      "MALT",
      "SF"
    ]
  },
  {
    "category": "Logistics Technology",
    "subCategory": "Transportation Solutions",
    "availableIn": [
      "MTE",
      "MALT",
      "SF"
    ]
  },
  {
    "category": "Logistics Technology",
    "subCategory": "Trays, Containers, Pallets",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Logistics Technology",
    "subCategory": "Warehouse Technology",
    "availableIn": [
      "MTE",
      "MALT",
      "SF"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "3D Printers",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Abrasive/Polishing Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Bending & Folding Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Blanking, Nibbling and Punching Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Boring Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Broaching Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Casting Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Chamferring Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Cutting Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Deburring Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Drawing Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Drilling Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "EDM/ECM",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Forging Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Gear Cutting Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Grinding Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Hobbing Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Honing Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Laser Cutting Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Laser Marking/Engraving Machines",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Lathe Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Machine Tool Parts",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Machining Center",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Materials",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Metal Cutting Machine Tool",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Metal Forming, Wire/Tube Forming",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Metal Stamping Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Milling Machines",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Notching Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Planing Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Riveting Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Sawing Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Shaping Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Shearing Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Slotting Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Tapping Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Threading Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Welding Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Machine Tool & Metalworking",
    "subCategory": "Wire Cutting Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Metrology",
    "subCategory": "3D Coordinate Measuring Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Metrology",
    "subCategory": "3D/Laser Scanner",
    "availableIn": [
      "MTE",
      "MALT",
      "SF"
    ]
  },
  {
    "category": "Metrology",
    "subCategory": "AR/VR Technologies",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Metrology",
    "subCategory": "Balancing/Testing Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Metrology",
    "subCategory": "Coordinate Measuring Machine",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Metrology",
    "subCategory": "Image Processing & Vision",
    "availableIn": [
      "MTE",
      "MALT",
      "SF"
    ]
  },
  {
    "category": "Metrology",
    "subCategory": "Instrumentation",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Metrology",
    "subCategory": "Metallurgical Microscope/Micrometer",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Metrology",
    "subCategory": "Meters, Gauges, Calibration",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Metrology",
    "subCategory": "Optical Measuring Equipment",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Metrology",
    "subCategory": "Positioning Systems",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Metrology",
    "subCategory": "Probes",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Metrology",
    "subCategory": "Profile Projector",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Metrology",
    "subCategory": "Quality Assurance",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Metrology",
    "subCategory": "Surface & Hardness Testing",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Metrology",
    "subCategory": "X-Ray, CT System",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Packaging / Processing Solutions",
    "subCategory": "Automated Packaging Systems",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Packaging / Processing Solutions",
    "subCategory": "Packaging, Item Sorting & Picking Equipment",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Packaging / Processing Solutions",
    "subCategory": "Scaling & Measuring Equipment",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Softwares, IT Systems",
    "subCategory": "CAD/CAM/CAE Software",
    "availableIn": [
      "MTE",
      "SF"
    ]
  },
  {
    "category": "Softwares, IT Systems",
    "subCategory": "Condition Monitoring",
    "availableIn": [
      "MTE",
      "SF"
    ]
  },
  {
    "category": "Softwares, IT Systems",
    "subCategory": "Data Management & IOT Technology",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Softwares, IT Systems",
    "subCategory": "Energy Management",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Softwares, IT Systems",
    "subCategory": "Factory & Asset Management",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Softwares, IT Systems",
    "subCategory": "Human Machine Interface /Communication Systems",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Softwares, IT Systems",
    "subCategory": "IT Infrastructure & Supplies",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Softwares, IT Systems",
    "subCategory": "Logistics Softwares",
    "availableIn": [
      "MTE",
      "MALT",
      "SF"
    ]
  },
  {
    "category": "Softwares, IT Systems",
    "subCategory": "Manufacturing Software & IT",
    "availableIn": [
      "MTE",
      "MALT",
      "SF",
      "Pack Expo"
    ]
  },
  {
    "category": "Softwares, IT Systems",
    "subCategory": "Prototyping System",
    "availableIn": [
      "MTE",
      "SF"
    ]
  },
  {
    "category": "Tools & Toolings",
    "subCategory": "Clamping & Gripping Tools",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Tools & Toolings",
    "subCategory": "Cutting Tools, CBN, End Mills",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Tools & Toolings",
    "subCategory": "Hotrunner System",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Tools & Toolings",
    "subCategory": "Moulding",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Tools & Toolings",
    "subCategory": "Tool Storage Equipments",
    "availableIn": [
      "MTE",
      "MALT"
    ]
  },
  {
    "category": "Tools & Toolings",
    "subCategory": "Tungsten Carbide/Precision/Diamond Tools",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Tools & Toolings",
    "subCategory": "Workpiece Holding",
    "availableIn": [
      "MTE"
    ]
  },
  {
    "category": "Packaging / Processing Solutions",
    "subCategory": "Chemicals",
    "availableIn": [
      "Pack Expo"
    ]
  },
  {
    "category": "Packaging / Processing Solutions",
    "subCategory": "Packaging Materials",
    "availableIn": [
      "Pack Expo"
    ]
  },
  {
    "category": "Packaging / Processing Solutions",
    "subCategory": "Packaging Services & Supplies",
    "availableIn": [
      "Pack Expo"
    ]
  },
  {
    "category": "Packaging / Processing Solutions",
    "subCategory": "Food & Beverage Processing Equipment/Machine",
    "availableIn": [
      "Pack Expo"
    ]
  },
  {
    "category": "Packaging / Processing Solutions",
    "subCategory": "Life Sciences Processing Equipment/Machinery",
    "availableIn": [
      "Pack Expo"
    ]
  }
]

const categoryList = [
  {
    "label": "Automation",
    "value": "CAT-0",
    "subCategory": [
      {
        "label": "Collaborative Robots",
        "value": "SUBCAT-0"
      },
      {
        "label": "Control Technology",
        "value": "SUBCAT-1"
      },
      {
        "label": "Drives Systems & Components",
        "value": "SUBCAT-2"
      },
      {
        "label": "Sensor Technology",
        "value": "SUBCAT-3"
      },
      {
        "label": "Industrial Robots",
        "value": "SUBCAT-4"
      }
    ]
  },
  {
    "label": "Factory Equipment & Industrial Supplies",
    "value": "CAT-1",
    "subCategory": [
      {
        "label": "Accessories, Components & Parts",
        "value": "SUBCAT-5"
      },
      {
        "label": "Air Delivery Solutions",
        "value": "SUBCAT-6"
      },
      {
        "label": "Bearings",
        "value": "SUBCAT-7"
      },
      {
        "label": "Cleaning Equipment, Chemicals",
        "value": "SUBCAT-8"
      },
      {
        "label": "Cooling & Lubrication",
        "value": "SUBCAT-9"
      },
      {
        "label": "Fasteners",
        "value": "SUBCAT-10"
      },
      {
        "label": "Filters, Fittings & Hoses",
        "value": "SUBCAT-11"
      },
      {
        "label": "Flooring",
        "value": "SUBCAT-12"
      },
      {
        "label": "Heat Treatment Solutions",
        "value": "SUBCAT-13"
      },
      {
        "label": "HVAC",
        "value": "SUBCAT-14"
      },
      {
        "label": "Hydraulics",
        "value": "SUBCAT-15"
      },
      {
        "label": "Mechanical Infrastructure",
        "value": "SUBCAT-16"
      },
      {
        "label": "Pneumatics",
        "value": "SUBCAT-17"
      },
      {
        "label": "Power Tools",
        "value": "SUBCAT-18"
      },
      {
        "label": "Safety Product & Equipments",
        "value": "SUBCAT-19"
      },
      {
        "label": "Surface Finishing Solutions",
        "value": "SUBCAT-20"
      }
    ]
  },
  {
    "label": "General Services",
    "value": "CAT-2",
    "subCategory": [
      {
        "label": "Government Agencies/Ministries",
        "value": "SUBCAT-21"
      },
      {
        "label": "Logistic Services & Outsourcing",
        "value": "SUBCAT-22"
      },
      {
        "label": "Repair & Maintenance",
        "value": "SUBCAT-23"
      },
      {
        "label": "Subcontracting & Outsourcing",
        "value": "SUBCAT-24"
      },
      {
        "label": "Trade Association",
        "value": "SUBCAT-25"
      },
      {
        "label": "Trade Publication",
        "value": "SUBCAT-26"
      },
      {
        "label": "Training & Certification",
        "value": "SUBCAT-27"
      }
    ]
  },
  {
    "label": "Logistics Technology",
    "value": "CAT-3",
    "subCategory": [
      {
        "label": "Auto-ID/IOT",
        "value": "SUBCAT-28"
      },
      {
        "label": "Automated Guided Vehicle (AGV)",
        "value": "SUBCAT-29"
      },
      {
        "label": "Automated Mobile Robot (AMR)",
        "value": "SUBCAT-30"
      },
      {
        "label": "Automated Storage & Retrieval System (AS/RS)",
        "value": "SUBCAT-31"
      },
      {
        "label": "Cold Chain Solutions",
        "value": "SUBCAT-32"
      },
      {
        "label": "Conveyors",
        "value": "SUBCAT-33"
      },
      {
        "label": "Industrial Casters",
        "value": "SUBCAT-34"
      },
      {
        "label": "Lifting Equipment & Machinery",
        "value": "SUBCAT-35"
      },
      {
        "label": "Material Handling Systems",
        "value": "SUBCAT-36"
      },
      {
        "label": "Racking & Storage Equipment",
        "value": "SUBCAT-37"
      },
      {
        "label": "Traffic Engineering",
        "value": "SUBCAT-38"
      },
      {
        "label": "Transportation Solutions",
        "value": "SUBCAT-39"
      },
      {
        "label": "Trays, Containers, Pallets",
        "value": "SUBCAT-40"
      },
      {
        "label": "Warehouse Technology",
        "value": "SUBCAT-41"
      }
    ]
  },
  {
    "label": "Machine Tool & Metalworking",
    "value": "CAT-4",
    "subCategory": [
      {
        "label": "3D Printers",
        "value": "SUBCAT-42"
      },
      {
        "label": "Abrasive/Polishing Machine",
        "value": "SUBCAT-43"
      },
      {
        "label": "Bending & Folding Machine",
        "value": "SUBCAT-44"
      },
      {
        "label": "Blanking, Nibbling and Punching Machine",
        "value": "SUBCAT-45"
      },
      {
        "label": "Boring Machine",
        "value": "SUBCAT-46"
      },
      {
        "label": "Broaching Machine",
        "value": "SUBCAT-47"
      },
      {
        "label": "Casting Machine",
        "value": "SUBCAT-48"
      },
      {
        "label": "Chamferring Machine",
        "value": "SUBCAT-49"
      },
      {
        "label": "Cutting Machine",
        "value": "SUBCAT-50"
      },
      {
        "label": "Deburring Machine",
        "value": "SUBCAT-51"
      },
      {
        "label": "Drawing Machine",
        "value": "SUBCAT-52"
      },
      {
        "label": "Drilling Machine",
        "value": "SUBCAT-53"
      },
      {
        "label": "EDM/ECM",
        "value": "SUBCAT-54"
      },
      {
        "label": "Forging Machine",
        "value": "SUBCAT-55"
      },
      {
        "label": "Gear Cutting Machine",
        "value": "SUBCAT-56"
      },
      {
        "label": "Grinding Machine",
        "value": "SUBCAT-57"
      },
      {
        "label": "Hobbing Machine",
        "value": "SUBCAT-58"
      },
      {
        "label": "Honing Machine",
        "value": "SUBCAT-59"
      },
      {
        "label": "Laser Cutting Machine",
        "value": "SUBCAT-60"
      },
      {
        "label": "Laser Marking/Engraving Machines",
        "value": "SUBCAT-61"
      },
      {
        "label": "Lathe Machine",
        "value": "SUBCAT-62"
      },
      {
        "label": "Machine Tool Parts",
        "value": "SUBCAT-63"
      },
      {
        "label": "Machining Center",
        "value": "SUBCAT-64"
      },
      {
        "label": "Materials",
        "value": "SUBCAT-65"
      },
      {
        "label": "Metal Cutting Machine Tool",
        "value": "SUBCAT-66"
      },
      {
        "label": "Metal Forming, Wire/Tube Forming",
        "value": "SUBCAT-67"
      },
      {
        "label": "Metal Stamping Machine",
        "value": "SUBCAT-68"
      },
      {
        "label": "Milling Machines",
        "value": "SUBCAT-69"
      },
      {
        "label": "Notching Machine",
        "value": "SUBCAT-70"
      },
      {
        "label": "Planing Machine",
        "value": "SUBCAT-71"
      },
      {
        "label": "Riveting Machine",
        "value": "SUBCAT-72"
      },
      {
        "label": "Sawing Machine",
        "value": "SUBCAT-73"
      },
      {
        "label": "Shaping Machine",
        "value": "SUBCAT-74"
      },
      {
        "label": "Shearing Machine",
        "value": "SUBCAT-75"
      },
      {
        "label": "Slotting Machine",
        "value": "SUBCAT-76"
      },
      {
        "label": "Tapping Machine",
        "value": "SUBCAT-77"
      },
      {
        "label": "Threading Machine",
        "value": "SUBCAT-78"
      },
      {
        "label": "Welding Machine",
        "value": "SUBCAT-79"
      },
      {
        "label": "Wire Cutting Machine",
        "value": "SUBCAT-80"
      }
    ]
  },
  {
    "label": "Metrology",
    "value": "CAT-5",
    "subCategory": [
      {
        "label": "3D Coordinate Measuring Machine",
        "value": "SUBCAT-81"
      },
      {
        "label": "3D/Laser Scanner",
        "value": "SUBCAT-82"
      },
      {
        "label": "AR/VR Technologies",
        "value": "SUBCAT-83"
      },
      {
        "label": "Balancing/Testing Machine",
        "value": "SUBCAT-84"
      },
      {
        "label": "Coordinate Measuring Machine",
        "value": "SUBCAT-85"
      },
      {
        "label": "Image Processing & Vision",
        "value": "SUBCAT-86"
      },
      {
        "label": "Instrumentation",
        "value": "SUBCAT-87"
      },
      {
        "label": "Metallurgical Microscope/Micrometer",
        "value": "SUBCAT-88"
      },
      {
        "label": "Meters, Gauges, Calibration",
        "value": "SUBCAT-89"
      },
      {
        "label": "Optical Measuring Equipment",
        "value": "SUBCAT-90"
      },
      {
        "label": "Positioning Systems",
        "value": "SUBCAT-91"
      },
      {
        "label": "Probes",
        "value": "SUBCAT-92"
      },
      {
        "label": "Profile Projector",
        "value": "SUBCAT-93"
      },
      {
        "label": "Quality Assurance",
        "value": "SUBCAT-94"
      },
      {
        "label": "Surface & Hardness Testing",
        "value": "SUBCAT-95"
      },
      {
        "label": "X-Ray, CT System",
        "value": "SUBCAT-96"
      }
    ]
  },
  {
    "label": "Packaging / Processing Solutions",
    "value": "CAT-6",
    "subCategory": [
      {
        "label": "Automated Packaging Systems",
        "value": "SUBCAT-97"
      },
      {
        "label": "Packaging, Item Sorting & Picking Equipment",
        "value": "SUBCAT-98"
      },
      {
        "label": "Scaling & Measuring Equipment",
        "value": "SUBCAT-99"
      },
      {
        "label": "Chemicals",
        "value": "SUBCAT-117"
      },
      {
        "label": "Packaging Materials",
        "value": "SUBCAT-118"
      },
      {
        "label": "Packaging Services & Supplies",
        "value": "SUBCAT-119"
      },
      {
        "label": "Food & Beverage Processing Equipment/Machine",
        "value": "SUBCAT-120"
      },
      {
        "label": "Life Sciences Processing Equipment/Machinery",
        "value": "SUBCAT-121"
      }
    ]
  },
  {
    "label": "Softwares, IT Systems",
    "value": "CAT-7",
    "subCategory": [
      {
        "label": "CAD/CAM/CAE Software",
        "value": "SUBCAT-100"
      },
      {
        "label": "Condition Monitoring",
        "value": "SUBCAT-101"
      },
      {
        "label": "Data Management & IOT Technology",
        "value": "SUBCAT-102"
      },
      {
        "label": "Energy Management",
        "value": "SUBCAT-103"
      },
      {
        "label": "Factory & Asset Management",
        "value": "SUBCAT-104"
      },
      {
        "label": "Human Machine Interface /Communication Systems",
        "value": "SUBCAT-105"
      },
      {
        "label": "IT Infrastructure & Supplies",
        "value": "SUBCAT-106"
      },
      {
        "label": "Logistics Softwares",
        "value": "SUBCAT-107"
      },
      {
        "label": "Manufacturing Software & IT",
        "value": "SUBCAT-108"
      },
      {
        "label": "Prototyping System",
        "value": "SUBCAT-109"
      }
    ]
  },
  {
    "label": "Tools & Toolings",
    "value": "CAT-8",
    "subCategory": [
      {
        "label": "Clamping & Gripping Tools",
        "value": "SUBCAT-110"
      },
      {
        "label": "Cutting Tools, CBN, End Mills",
        "value": "SUBCAT-111"
      },
      {
        "label": "Hotrunner System",
        "value": "SUBCAT-112"
      },
      {
        "label": "Moulding",
        "value": "SUBCAT-113"
      },
      {
        "label": "Tool Storage Equipments",
        "value": "SUBCAT-114"
      },
      {
        "label": "Tungsten Carbide/Precision/Diamond Tools",
        "value": "SUBCAT-115"
      },
      {
        "label": "Workpiece Holding",
        "value": "SUBCAT-116"
      }
    ]
  }
]

const LIST_0 = {
  label: 'What is the type of your organisation?',
  multiOption: false,
  options: [
    'Manufacturer',
    'Agent / Distributor / Import & Export',
    'Domestic and International Trade',
    'Retailer',
    'System Integrator',
    'Business services provider',
    'Government, public authorities, institutions',
    'R & D enterprises or institutes / Colleges and universities',
    'Investors, Financing',
    'Media Publisher',
    'Others'
  ]
}

const LIST_1 = {
  label: 'What is your industry ? (Multiple choice)',
  multiOption: true,
  options: [
    'Aerospace',
    '3PL / Freight Forwarders / Warehouse Owners',
    'Aerospace & Automative, Parts Manufacturing',
    'Air Conditioning & Refrigeration',
    'Airport / Port',
    'Architecture & Design',
    'Automotives',
    'Building & Construction',
    'Chemical',
    'Cold chain',
    'Consumer Goods Manufacturing',
    'Daily chemicals',
    'E-commerce',
    'Electrical / Electronics Manufacturing',
    'Electrical & Electronics',
    'Energy / Power supply',
    'Engineering, Contracting Manufacturing & Turnkey Projects',
    'Fashion',
    'Food & Beverage',
    'Furniture',
    'Garment',
    'Industrial manufacturing',
    'Information software and solution',
    'Infrastructure & Facilities',
    'IT & Telecommunications',
    'Logistics & Supply Chain Management',
    'Logistics real estate',
    'Processing',
    'Material Testing & Inspection',
    'Mechanical manufacturing',
    'Medical, Dentistry,Medical Devices',
    'Metal Component & Products Manufacturing',
    'Metallurgy',
    'National defense',
    'Packaging / Printing',
    'Parts & Bearing',
    'Oil & Gas, Petroleum & Petrochemical',
    'Pharmaceutical & Biotechnology, Life Sciences',
    'Plastics & Rubber',
    'Postal/ Express delivery',
    'Raw Materials',
    'Related service (bank, insurance, consultation etc)',
    'Rubber & Plastic',
    'Semiconductor',
    'Ship Building / Marine Engineering',
    'Storage service',
    'Tobacco',
    'Transport, Storage & Handling',
    'Urban Solutions',
    'Others'
  ]
}

const LIST_2 = {
  label: 'What is your main working area within your company?',
  multiOption: false,
  options: [
    'Business / company / general management',
    'Research and development, design',
    'Manufacture, production, quality control',
    'Purchasing, procurement',
    'Finance, accounting, controlling',
    'Information / communication technology',
    'Human Resources, Administration',
    'Sales, Distribution',
    'Marketing, advertising, PR',
    'Logistics: material management, warehouse, transport',
    'Maintenance, repair',
    'Others'
  ]
}

const LIST_3 = {
  label: 'Which show are you here to visit?',
  multiOption: true,
  options: [
    {label: 'MTE 2024', key: 'MTE'},
    {label: 'MALT 2024', key: 'MALT'},
    {label: 'SF 2024', key: 'SF'},
    {label: 'Pack Expo 2024', key: 'Pack Expo'}
  ],
  categoryOptions: {
    label: 'What field are you interested in?',
    subCatLabel: 'What sub field are you interested in?',
    multiOption: true,
    options: categoryList
  }
}

const LIST_4 = {
  label: 'What role do you have in purchasing decisions at your company?',
  multiOption: false,
  options: [
    'Decision-Maker / Final Authority',
    'Evaluate / Implement purchasing',
    'Recommendation / Collect Information for purchasing'
  ]
}

const LIST_5 = {
  label: 'What are your objectives for visiting this fair? (Multiple choice)',
  multiOption: false,
  options: [
    'To keep up with latest industry trends',
    'To look for new products and solutions',
    'To gather information for purchasing decisions',
    'To purchase, place an order',
    'To find potential customers',
    'To find potential suppliers',
    `Evaluate next years' attendance`,
    'Contact existing business partners',
    'Others'
  ]
}

const LIST_6 = {
  label: 'How did you hear about our shows?(Multiple choice)',
  multiOption: true,
  options: [
    `Organiser's Phone call`,
    'E-newsletter from Organiser',
    'SMS from Organiser',
    'Facebook',
    'LinkedIN',
    'Other Social Media platform',
    'Show Website',
    'Invitation from Exhibitors',
    'Friends / Colleagues Recommendation',
    'Search Engine: Google / Baidu etc.',
    'Once Visited',
    'Industrial Media / Magazine',
    'Trade Association',
    'Outdoor Billboard / Buntings',
  ]
}

exports.LIST_0 = LIST_0;
exports.LIST_1 = LIST_1;
exports.LIST_2 = LIST_2;
exports.LIST_3 = LIST_3;
exports.LIST_4 = LIST_4;
exports.LIST_5 = LIST_5;
exports.LIST_6 = LIST_6;

exports.famousList = famousList
exports.countryList = countryList
// exports.piList = piList
// exports.siList = siList
// exports.mjList = mjList
// exports.pjList = pjList
// exports.mpList = mpList
// exports.cList = cList
// exports.aList = aList
// exports.ppList = ppList
// exports.pbList = pbList
exports.salutationList = salutationList