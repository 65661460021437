import React from 'react';
import './../App.css';
import Barcode from 'react-barcode';
import queryString from 'query-string';
import { Input, notification, Button } from 'antd';
import { SERVER_URL } from '../constant'
import DateAddress from '../components/dateAddress';
import Logo from '../components/logo';
class BarcodeEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      barcode: null,
      name: '',
      email: '',
      designation: '',
    };
  }

  updateInformation() {
    console.log('update')
  }

  componentDidMount() {
    let i = ''
    const query = queryString.parse(this.props.location.search)
    if (Object.entries(query).length !== 0) {
      i = query.i
    } else if (this.props.location.state) {
      i = this.props.location.state.i
    }
    if (i !== '') {
      fetch(`${SERVER_URL}/visitor?i=${i}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(async (res) => {
          const response = await res.json()
          this.setState({barcode: response.body.key.toString()})
          this.setState({name: response.body.name.toString()})
          this.setState({email: response.body.email.toString()})
          this.setState({designation: response.body.designation.toString()})
          this.setState({telephone: response.body.telephone})
          this.setState({company: response.body.company})
          this.setState({address1: response.body.address1})
          this.setState({address2: response.body.address2})
          this.setState({postCode: response.body.postCode})
          this.setState({state: response.body.state})
          this.setState({country: response.body.country})
          this.setState({website: response.body.website})
        })
    } else {
      notification.open({
        message: 'No valid link / ID',
        description: 'Please go to your email and get the link',
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
    }
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <div className="card-header">
            {/* <img className="logoImage mb-10" src="https://mtexpo.com.my/wp-content/uploads/2020/02/LOGO-2020-trans.png" alt="MTE Logo"/> */}
            <Logo/>
            <DateAddress></DateAddress>
          </div>
          {this.state.barcode && <Barcode value={this.state.barcode} />}
          <span className="selectMore">* You can update your information here</span>
          <Input
            className="inputClass"
            placeholder={'Name 名字'}
            value={this.state.name}
            onChange={(event) => this.handleChange('name', event.target.value)}
          />
          <Input
            className="inputClass"
            placeholder={'Email 邮件'}
            value={this.state.email}
            onChange={(event) => this.handleChange('email', event.target.value)}
          />
          <Input
            className="inputClass"
            placeholder={'Designation 职称'}
            value={this.state.designation}
            onChange={(event) => this.handleChange('designation', event.target.value)}
          />
          <Input
            className="inputClass"
            placeholder={'Handphone No. 电话号码'}
            value={this.state.telephone}
            onChange={(event) => this.handleChange('telephone', event.target.value)}
          />
          <Input
            className="inputClass"
            placeholder={'Company 公司'}
            value={this.state.company}
            onChange={(event) => this.handleChange('company', event.target.value)}
          />
          <Input
            className="inputClass"
            placeholder={'Address 1 地址 1'}
            value={this.state.address1}
            onChange={(event) => this.handleChange('address1', event.target.value)}
          />
          <Input
            className="inputClass"
            placeholder={'Address 2 地址 2'}
            value={this.state.address2}
            onChange={(event) => this.handleChange('address2', event.target.value)}
          />
          <Input
            className="inputClass"
            placeholder={'Post Code 邮区'}
            value={this.state.postCode}
            onChange={(event) => this.handleChange('postCode', event.target.value)}
          />
          <Input
            className="inputClass"
            placeholder={'State 州属'}
            value={this.state.state}
            onChange={(event) => this.handleChange('state', event.target.value)}
          />
          <Input
            className="inputClass"
            placeholder={'Country 国家'}
            value={this.state.country}
            onChange={(event) => this.handleChange('country', event.target.value)}
          />
          <Input
            className="inputClass"
            placeholder={'Website 网址'}
            value={this.state.website}
            onChange={(event) => this.handleChange('website', event.target.value)}
          />
          <Button className="nextButton" onClick={() => this.updateInformation()}>
            Update Information
          </Button>
        </header>
      </div>
    );
  }
}

export default BarcodeEdit;
