import React from 'react';
import './../App.css';
import { Input, Button, Modal } from 'antd';
import {SERVER_URL, MATCH_NON_ENGLISH, BOOTH_NUMBER_REG} from '../constant'
import DateAddress from '../components/dateAddress';
import Logo from '../components/logo';
class InviteVipP2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibitorCompany: '',
      boothNumber: '',
      exhibitorCompanyRequired: false,
      boothNumberRequired: false,
      invalidLengthText: [],
      nonEnglishInputs: []
    };
  }

  componentDidMount() {
    // Modal.error({
    //   title: "Sorry...",
    //   content: "Our service is currently unavailable.",
    // });
  }

  handleChange(field, value) {
    this.setState({[field]: value})
    this.setState({[field + 'Required']: false})
    if (field === 'boothNumber') {
      this.setState({invalidBoothNumberFormat: false})
    }
    const match = MATCH_NON_ENGLISH.test(value)
    const array = this.state.nonEnglishInputs
    const index = array.indexOf(field)
    const array2 = this.state.invalidLengthText
    const index2 = array2.indexOf(field)

    if ( index >= 0 && !match) {
      array.splice(index, 1)
      this.setState({'nonEnglishInputs': array})
    } else if (index < 0 && match) {
      array.push(field)
      this.setState({'nonEnglishInputs': array})
    }

    if (index2 >= 0 && this.state[field].length >= 3) {
      array2.splice(index2, 1)
      this.setState({'invalidLengthText': array2})
    } else if (index2 < 0 && value.length > 0 && value.length < 2) {
        array2.push(field)
        this.setState({'invalidLengthText': array2})
      }
    }

  checkTextLength() {
    let allValidLength = true
    const array = this.state.invalidLengthText
    if (this.state.exhibitorCompany.length < 3 && array.indexOf('exhibitorCompany') < 0) {
      allValidLength = false
      this.setState({exhibitorCompanyRequired: true})
    }

    this.setState({'invalidLengthText': array})
    return !allValidLength
  }

  next() {
    const profile = {
      company: this.state.exhibitorCompany,
      boothNumber: this.state.boothNumber
    }
    let errorPage = false
    errorPage = this.checkTextLength()
    if (!this.state.exhibitorCompany || this.state.nonEnglishInputs.includes('exhibitorCompany') || this.state.invalidLengthText.includes('exhibitorCompany')) {
      errorPage = true
      this.setState({exhibitorCompanyRequired: true})
    }
    const matchBoothNumber = BOOTH_NUMBER_REG.test(this.state.boothNumber)
    if (!this.state.boothNumber || this.state.nonEnglishInputs.includes('boothNumber') || !matchBoothNumber) {
      errorPage = true
      this.setState({boothNumberRequired: true})
      if (this.state.nonEnglishInputs.includes('boothNumber') || !matchBoothNumber) {
        this.setState({invalidBoothNumberFormat: true})
      }
    }
    // alert(errorPage)
    if (!errorPage) {
      localStorage.setItem("exhibitor", JSON.stringify(profile))
      this.props.history.push('/vipP2', profile)
    }
  }

  render() {
    return (
      <div className="App">
        <header className="App-header full-page backgroundLightGrey">
          <div className='card-header'>
            {/* <img className="logoImage mb-10" src={require('../image/logo1.png')} alt="MTE logo" /> */}
            <Logo/>
            <DateAddress/>
          </div>
          <br/>
          {/* <span className="title welcomeMessage">For manual registration, ﬁrst we need to verify your email address. Please check your email address for registration email. Your email will contain the link to continue with your registration.</span> */}
          <div className="border">
            <span className="bigTitle">Exhibitor VIP Invitation</span>
            <span className="smallSubtitleWithoutMarginBottom">Exhibitor Company Name:</span>
            {/* <span className="smallSubtitle">我们已将您的登录说明发送给：</span> */}
            <div className='inputClass'>
              <Input
                className= {"emailInputClass " + (this.state.exhibitorCompanyRequired && 'redPlaceholder')}
                placeholder="Exhibitor Company Name"
                value={this.state.exhibitorCompany}
                onChange={(event) => this.handleChange('exhibitorCompany', event.target.value)}
              />
              {((this.state.nonEnglishInputs.includes('exhibitorCompany') || this.state.invalidLengthText.includes('exhibitorCompany')) && this.state.exhibitorCompanyRequired) && <span className='error-message'>{this.state.nonEnglishInputs.includes('exhibitorCompany') ? 'Please enter in english.': 'Company name too short. Please enter real company name.'}</span>}
            </div>
            <span className="smallSubtitleWithoutMarginBottom">Booth Number:</span>
            <div className='inputClass'>
              <Input
                className= {"emailInputClass " + (this.state.exhibitorCompanyRequired && 'redPlaceholder')}
                placeholder="Booth Number"
                value={this.state.boothNumber}
                onChange={(event) => this.handleChange('boothNumber', event.target.value)}
                maxLength={10}
              />
              {((this.state.nonEnglishInputs.includes('boothNumber') || this.state.invalidBoothNumberFormat) &&
                this.state.boothNumberRequired) && <span className='error-message'>{(this.state.boothNumber.length > 10) ?
                  'Maximum 10 characters.' : 'Space is not allow.'}</span>}
            </div>
            <div className="bottom-area">
              <Button onClick={() => this.next()} className="nextButton" >
                Next
              </Button>
            </div>
          </div>
          {/* <div class="reminder size1"> Our service is currently unavailable.</div> */}
        </header>
      </div>
    );
  }
}

export default InviteVipP2;
