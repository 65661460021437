import React from 'react';
import './../App.css';
import { Input, Button, notification, Spin } from 'antd';
// import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import Cookies from 'universal-cookie';
import {SERVER_URL} from '../constant'
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import queryString from 'query-string';
import Logo from '../components/logo';
import DateAddress from '../components/dateAddress';
import { message } from '../resources/dict/dict-message';

const cookies = new Cookies();
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailValid: true,
      loading: false,
      email2: '',
      id: null,
      googleBtnWidth: '0'
    };
    this.googleBtn = React.createRef();
  }

  responseFacebook(response) {
    const that = this
    const body = {
      platform: 'facebook',
      platformID: response.id,
      id: this.state.id,
      profile: {
        name: response.name,
        email: response.email,
      },
    }
    fetch(`${SERVER_URL}/login`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then((json) => {
        const profile = { i: json.body.i, platform: 'Facebook', id: this.state.id }
        if (json.body.m === 'pne') {
          that.props.history.push('/register', profile)
        } else if (json.body.m === 'pe' && json.body.s === 'c') {
          that.props.history.push('/barcode', profile)
        } else if (json.body.m === 'pe' && json.body.s === 'p') {
          that.props.history.push('/register', profile)
        } else if (json.body.m === 'ee' && json.body.s === 'c') {
          that.props.history.push('/barcode', profile)
        } else if (json.body.m === 'ee' && json.body.s === 'p') {
          that.props.history.push('/register', profile)
        } else {
          notification.open({
            message: 'Error happened',
            description: 'Please consult staff for help',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
        }
      })
      .catch(function(error) {
        console.log(error)
      });
  }

  componentClicked(response) {
    this.setState({loading: true})
  }

  googleSuccess(response) {
    this.setState({loading: true})
    const that = this
    const jwt = response.credential.split('.')
    const payload = JSON.parse(window.atob(jwt[1]))
    const body = {
      platform: 'google',
      platformID: payload.sub,
      profile: payload,
      email: payload.email,
      id: this.state.id
    }
    fetch(`${SERVER_URL}/login`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then((json) => {
        const profile = { i: json.body.i, platform: 'Google', id: this.state.id }
        if (json.body.m === 'pne') {
          that.props.history.push('/register', profile)
        } else if (json.body.m === 'pe' && json.body.s === 'c') {
          that.props.history.push('/barcode', profile)
        } else if (json.body.m === 'pe' && json.body.s === 'p') {
          that.props.history.push('/register', profile)
        } else if (json.body.m === 'ee' && json.body.s === 'c') {
          that.props.history.push('/barcode', profile)
        } else if (json.body.m === 'ee' && json.body.s === 'p') {
          that.props.history.push('/register', profile)
        } else {
          notification.open({
            message: 'Error happened',
            description: 'Please consult staff for help',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
        }
      })
      .catch(function(error) {
        console.log(error)
      });
  }

  googleFailure(response) {
    // console.log('error: ' + JSON.stringify(response));
  }

  handleChange(field, value) {
    this.setState({[field]: value})
  }

  emailSignup() {
    const emailValid = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (emailValid) {
      const that = this
      const body = {
        email: this.state.email,
        platform: 'Web',
        id: this.state.id
      }
      fetch(`${SERVER_URL}/signup`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then(response => response.json())
        .then((json) => {
          const profile = { e: this.state.email, id: this.state.id }
          if (json.body.m === 'ene') {
            that.props.history.push('/emailverification', profile)
          } else if (json.body.m === 'ee' && json.body.s === 'c') {
            that.props.history.push('/emaillogin', profile)
          } else if (json.body.m === 'ee' && json.body.s === 'p') {
            that.props.history.push('/emailverification', profile)
          } else {
            notification.open({
              message: 'Error happened',
              description: 'Please consult staff for help',
              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
          }
        })
        .catch(function(error) {
          console.log(error)
        });
    } else {
      this.setState({emailValid: false})
    }
  }
  inviteVIP() {
    this.props.history.push('/inviteVIP')
  }
  getMessage(name) {
    return message[name]
  }
  emailLogin() {
    const email2Valid = this.state.email2.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (email2Valid) {
      const that = this
      const body = {
        email: this.state.email2,
        id: this.state.id
      }
      fetch(`${SERVER_URL}/login`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then(response => response.json())
        .then((json) => {
          const profile = { e: this.state.email2, id: this.state.id}
          if (json.body.m === 'ene') {
            let path = '/emailnotfound'
            if (this.state.id) {
              path += '?id=' + this.state.id;
            }
            that.props.history.push(path, profile)
          } else if (json.body.m === 'ee' && json.body.s === 'c') {
            that.props.history.push('/emaillogin', profile)
          } else if (json.body.m === 'ee' && json.body.s === 'p') {
            that.props.history.push('/emailverification', profile)
          } else {
            notification.open({
              message: 'Error happened',
              description: 'Please consult staff for help',
              onClick: () => {
                console.log('Notification Clicked!');
              },
            });
          }
        })
        .catch(function(error) {
          console.log(error)
        });
    } else {
      this.setState({emailValid: false})
    }
  }

  calculateGoogleBtnWidth() {
    // const width = (this.googleBtn.current.clientWidth).toString()
    // this.setState({'googleBtnWidth': width})
  }

  componentDidMount() {
    // this.calculateGoogleBtnWidth()
    const query = queryString.parse(this.props.location.search)
    if (query.id) {
      this.setState({id: query.id})
    }
    cookies.remove('mteRegistration')
  }

  render() {
    return (
      <div className="App">
        <header className="App-header backgroundLightGrey">
          <div className="card-header">
            <Logo/>
            <DateAddress/>
          </div>
          <span className="title welcomeMessage">Welcome to {this.getMessage('event-name')} Visitor Registration. If you are first time visitor, please click on the login option to create your account. You can also login below to retrieve your QR Code for your visitor badge collection.</span>
          <div className="border">
            <br/>
            <span className="smallTitle">SIGN UP</span>
            {/* {this.state.loading ? <Spin /> :
            <div>
              <FacebookLogin
                appId="1273656859894812"
                autoLoad={false}
                fields="name,email,picture"
                onClick={() => this.componentClicked}
                callback={(res) => this.responseFacebook(res)}
                cssClass="loginBtn loginBtn--facebook"
                textButton="Continue with Facebook"
                isMobile={false}
              />
              <div className="google-btn" ref={this.googleBtn}>
                <GoogleOAuthProvider clientId="115899716119-gd9hkfb5oh35dtvc8hrjklvq1qon7256.apps.googleusercontent.com">
                  <GoogleLogin
                    // clientId="1077503034509-fo825s8r9k5al8k3had4eigh40r7p4fh.apps.googleusercontent.com"
                    // buttonText="Login"
                    className="loginBtn"
                    onSuccess={(response) => this.googleSuccess(response)}
                    onError={(response) => this.googleFailure(response)}
                    size="large"
                    auto_select={false}
                    useOneTap={false}
                    state_cookie_domain={'single_host_origin'}
                    locale="en"
                    width={this.state.googleBtnWidth}
                  />
                </GoogleOAuthProvider>
              </div>
            </div>} */}
            {/* <span className="smallSubtitleWithoutMarginBottom">Don’t have Facebook or Google ID?</span> */}
            <span className="smallSubtitle">Create your new account below:</span>
            <Input
              className="emailInputClass"
              placeholder="Email 电子邮箱"
              value={this.state.email}
              onChange={(event) => this.handleChange('email', event.target.value)}
            />
            {this.state.emailValid === false && <span className="title red">Email not valid!</span>}
            <Button onClick={() => this.emailSignup()} className="nextButton">
              Sign Up with Email
            </Button>

            <hr/>
            <span className="smallTitle">ALREADY REGISTERED?</span>
            <span className="smallSubtitle">Login with your Email below:</span>
            <Input
              className="emailInputClass"
              placeholder="Email 电子邮箱"
              value={this.state.email2}
              onChange={(event) => this.handleChange('email2', event.target.value)}
            />
            <Button onClick={() => this.emailLogin()} className="LoginButton">
              Login
            </Button>
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
        </header>
      </div>
    );
  }
}

export default Login;
