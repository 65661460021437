// export const SERVER_URL = "http://localhost:8003"
export const SERVER_URL = "https://mte-server-2001.herokuapp.com"

export const MATCH_NON_ENGLISH = /[^\x00-\x80!@#$&()\\-`.+,/\"<>\n]+/

// export const INVALID_TEXT_RANGE = /.[a-zA-Z0-9!@#$&()\\-`.+,/\"<>]{1,3}/

export const BOOTH_NUMBER_REG = /^[\S]{1,10}$/

export const EMAIL_REG = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/

export const PHONE_REG = /^\d+?-?\d+$/i

export const MAP_LOCATION = 'https://www.google.com/maps/place/Malaysia+International+Trade+and+Exhibition+Centre/@3.1790568,101.6656725,17z/data=!3m1!4b1!4m6!3m5!1s0x31cc48648f3437bd:0x24f3a3018c45b014!8m2!3d3.1790514!4d101.6682474!16s%2Fg%2F11f3p2tz_p?entry=ttu'